
.logoHome{
    position: fixed;
    top: 15px;
    right: 15px;
}

// ---------------MFA Settings dasboard---------------------//

.mfa-modal-p{
    max-width: "330px";
    margin-left: "auto";
    margin-right: "auto";
    text-align: "left"; 
}

//

.popup-err-bar{
    margin-top: 10px;
    height: 30;
    overflow: "hidden";
}

#ct-container{
    z-index: 1000005 !important;
}

#nprogress .bar {
  background: red !important;
  z-index: 1000005 !important;
}

#nprogress .peg {
  z-index: 1000005 !important;
  box-shadow: 0 0 10px red, 0 0 5px red !important;
}

#nprogress .spinner-icon {
  z-index: 1000005 !important;
  border-top-color: red !important;
  border-left-color: red !important;
}

.ms-DetailsHeader{
    background: #0181AE;
    
    padding-top: 0 !important;
}

.ms-DetailsHeader-cell{
    color: #ffffff;
}

.otp-rsnd-btn{
    margin-top: 1rem;
    cursor: pointer;
    text-decoration: dashed;
}

.sms-mfa-expiry{
    margin-bottom: 1rem !important;
    margin-top: -1rem;
    font-size: 13px;
    height: 20px;
}

// table pagination
/* .item must be first */
.item {
    align-items: center;
    color: #0181AE;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
  
  .disabled-page {
    color: #808e9b;
  }
  
  .active {
    border-bottom: solid 1px #0181AE;
   
    color: #0181AE;
    margin:0 5px 0 5px;
    button:active{
        border: none !important;
    }
  }
  
//   .break-me {
//   }
  
  .next {
    
    font-size: 4px;
    height: 60px;
    position: absolute;
    right: 0;
    width: 50px;
  }
  
  .pagination {
    align-items: center;
    background-color: transparent;// #0fbcf9;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 40%;
    
  }
  
  .pagination-page {
    font-weight: 700;
  }
  .pagination-page-click{
    font-weight: 700;
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
  
  .previous {
  
    font-size: 4px;
    height: 60px;
    left: 0;
    position: absolute;
    width: 50px;
  }
// eof pagination

.paper {
  position: relative;
  padding: 5px 5px 5px 5px;
  background-color: #ffffff;
}


.pdng-gen-20{
  padding: 10px 20px;
}

// fileDrop css


.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 700px;
  width: 100%;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: #eeeeee;
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #eeeeee;
  box-shadow: 0 0 13px 3px #eeeeee;
}

// EOF fileDrop css



// CK EDITOR
.ck-editor__editable_inline {
  min-height: 400px;
  max-height: 500px !important;
}
//EOF CK EDITOR


.preloader-paragon {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 30px;
  animation: l1 1s linear infinite alternate;
  color:#1bb0e4;
}
.preloader-paragon:before {
  content:"Loading...";
}
@keyframes l1 {to{opacity: 0}}