body {
    font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
    background: #ffffff;
}

.container {
    width: 90%;
    padding: 0 15px;
}

* {
    box-sizing: border-box;
}

//.................... GRID STYLES.........................//
.css-0 {
    overflow: visible !important;
}

$purple: #0181ae;
$font1: "Poppins", sans-serif !important;

.ms-Grid {
    padding: 0px 15px;
}

.ms-Grid-row {
    margin: 0 -15px;

}

.ms-Grid-col {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.d-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.no-gutters {
    margin-right: 0 !important;
    margin-left: 0 !important;

    >.ms-Grid-col {
        padding-right: 0 !important;
        padding-left: 0 !important;

        .ms-Grid {
            padding: 0px 15px;
        }
    }
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;

    &.ms-Grid-row {

        &::before,
        &::after {
            display: none;
        }
    }
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.m-0 {
    margin: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

mt-1 {
    margin-top: 10 !important;
}

.p-0 {
    padding: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.tableRowAsn th {
    font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    color: #fff;
    min-width: 100%;
    vertical-align: top;
    height: 42px;
    line-height: 42px;
    white-space: nowrap;
    box-sizing: content-box;
    padding-bottom: 1px;
    border-bottom: 1px solid rgb(234, 234, 234);
    cursor: default;
    user-select: none;
    background: #0181ae;
    padding-top: 0 !important;
}

.tableRowAsn tr:nth-child(even) {
    background-color: #f6f3f7;
}

figure {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    padding: 0;
    margin: 0;
}

p {
    margin: 0;
    padding: 0;
    color: #383838;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.text-right {
    text-align: right;
}

.cmnBtn {
    font-size: 14px;
    color: #ffffff;
    background-color: #0181ae;
    border: 1px solid #0181ae;
    font-weight: 400;
    border-radius: 2px;
    height: 38px;
    margin-top: 25px;

    &:hover {
        background-color: #02a2bc;
        border: 1px solid #02a2bc;
        text-decoration: none;

    }

    &:disabled {
        background-color: #bebbb8;
        border: 1px solid #bebbb8;
    }
}

.cmnBtn-sm {
    font-size: 13px;
    color: #ffffff;
    background-color: #0181ae;
    border: 1px solid #0181ae;
    font-weight: 400;
    border-radius: 0px;
    height: 32px;

    &:hover {
        background-color: #02a2bc;
        border: 1px solid #02a2bc;
    }

    &:disabled {
        background-color: #bebbb8;
        border: 1px solid #bebbb8;
    }
}

.cmnBtn-outline {
    font-size: 14px;
    color: #000000;
    border: 1px solid #000000;
    font-weight: 400;
    border-radius: 2px;
    height: 38px;
    margin-top: 25px;

    &:disabled {

        border: 1px solid #bebbb8;
        color: #bebbb8;
    }
}

.cmnCanelBtn {
    font-size: 14px;
    color: #0181ae;
    background-color: #ffffff;
    border: 1px solid #0181ae;
    font-weight: 400;
    border-radius: 2px;
    height: 38px;
    margin-top: 25px;

    &:hover {
        background-color: #0181ae1c;
        border: 1px solid #0181ae;
        color: #0181ae;
    }

    &:disabled {
        background-color: #bebbb8;
        border: 1px solid #bebbb8;
        color: #0181ae;
    }
}

.cmnSuccessBtn {
    font-size: 14px;
    color: #ffffff;
    background-color: #17b657;
    border: 1px solid #17b657;
    font-weight: 400;
    border-radius: 2px;
    height: 38px;
    margin-top: 25px;

    &:hover {
        background-color: #3fcb77;
        border: 1px solid #3fcb77;
    }

    &:disabled {
        background-color: #bebbb8;
        border: 1px solid #bebbb8;
    }
}

.cmnDangerBtn {
    font-size: 14px;
    color: #ffffff;
    background-color: #d30c2a;
    border: 1px solid #d30c2a;
    font-weight: 400;
    border-radius: 2px;
    height: 38px;
    margin-top: 25px;

    &:hover {
        background-color: #d30c2a;
        border: 1px solid #d30c2a;
    }

    &:disabled {
        background-color: #bebbb8;
        border: 1px solid #bebbb8;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.img-fluid {
    img {
        max-width: 100%;
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.ms-Checkbox-checkbox {
    height: 18px;
    width: 18px;
}

.is-checked {
    .ms-Checkbox-checkbox {
        border: 1px solid #0181ae;
        background: #0181ae;
    }
}

//.................... GRID STYLES.........................//

// ---------------LOGIN---------------------//
.login-wrp {
    background: #ffffff; // Ensure good contrast with other elements

    h1 {
        font-size: 52px;
        color: #ffffff; // Ensure sufficient contrast if the background is different
        font-weight: 600;
        position: absolute;
        bottom: 60px;
        left: 65px;

        @media (max-width: 1919px) {
            font-size: 45px;
        }

        @media (max-width: 1365px) {
            font-size: 40px;
        }

        @media (max-width: 1023px) {
            font-size: 30px;
            left: 30px;
        }

        @media (max-width: 639px) {
            display: none;
        }
    }

    h2 {
        font-size: 38px;
        color: #000000; // This should have good contrast on a white background
        font-weight: 600;
        margin-top: 30px;

        @media (max-width: 639px) {
            font-size: 25px;
            margin-top: 20px;
        }
    }

    h6 {
        font-size: 14px;
        color: #333333; // Darker color for better contrast
        margin-bottom: 30px;

        @media (max-width: 639px) {
            margin-bottom: 25px;
        }
    }

    h5 {
        font-size: 14px;
        color: #666666; // Slightly darker for better readability
        margin-bottom: 10px;

        .ms-Link {
            color: #0078d4; // Change to a more accessible color if needed
            font-weight: 500;
        }
    }

    .inputBox {
        .ms-TextField-fieldGroup {
            background: #f9f9f9; // Lighter background for better contrast
        }
    }

    .cmnBtn {
        background-color: #0078d4; // Example color with good contrast
        color: #ffffff !important; // Ensure text color contrasts with background
        &:hover {
            background-color: #005a9e; // Darker shade for hover effect
        }
        .ms-Button-label {
            color: #ffffff !important; /* Ensure button text is white */
        }
    }

    a {
        color: #0078d4; // Accessible link color
        &:hover {
            color: #005a9e; // Darker shade on hover
        }
    }

    span {
        color: #0078d4; // Ensure this color contrasts well with its background
        &:hover {
            color: #005a9e;
        }
    }
}


.inputBox {
    margin-bottom: 12px;

    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-TextField-fieldGroup {
        border: 1px solid #000000;
        border-radius: 2px;
        min-height: 42px;
    }

    .ms-Dropdown-title {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 42px;
        padding: 5px 28px 0px 8px;
    }

    .ms-Dropdown {
        width: 100%;
    }

    .ms-Dropdown-caretDownWrapper {
        top: 7px;
    }

    .ms-TextField-prefix {
        background: #f4f4f487;
    }
}

// style={ {chips: { border: "none", "border-radius": "2px" },optionContainer: {"z-index": "1000"} }}

.inputBoxMulti {
    margin-top: 5px;

    .search-wrapper {
        border: 1px solid;
        min-height: 42px;
        border-radius: 2px;

        .chip {
            background-color: #eaeaea;
            color: #312f2f;
        }
    }

    .optionListContainer {
        position: inherit;
        max-height: 150px;
        overflow: auto;

        .optionContainer {
            max-height: 150px;
        }

        li:hover {
            background-color: #eaeaea;
            color: #787878;
        }
    }
}

.multiBox-label {
    font-size: 12px;
    color: #000000;
    font-weight: 400;
    font-family: $font1;
    margin-bottom: 3px;
}

.inputArea {
    .ms-TextField-fieldGroup {
        border-radius: 2px;
        min-height: 100px;
    }
}

.inputBoxMini {
    margin-bottom: 12px;

    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-TextField-fieldGroup {
        border: 1px solid #000000;
        border-radius: 2px;
    }

    .ms-Dropdown-title {
        border: 1px solid #000000;
        border-radius: 2px;
    }

    .ms-Dropdown {
        width: 100%;
    }

    .ms-Dropdown-caretDownWrapper {
        top: 0px;
    }

    .ms-TextField-prefix {
        background: #f4f4f487;
    }
}

.inputBoxFilter {
    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-TextField-fieldGroup {
        border: 1px solid #000000;
        border-radius: 2px;
    }

    .ms-Dropdown-title {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px !important;
        line-height: 36px !important;
    }

    .ms-Dropdown {
        width: 160px;
    }

    .ms-Dropdown-caretDownWrapper {
        top: 0px;
        line-height: 40px !important;
    }

    .ms-TextField-prefix {
        background: #f4f4f487;
    }

}

.bgFig {
    width: 100%;
    height: 100%;
    background-size: cover;
    min-height: 100vh;
}

.formBox {
    width: 500px;
    margin: auto !important;
    padding: 30px !important;
}

.authentication-wrp {
    .inputBox {
        display: flex;
    }

    .ms-TextField {
        margin: 0 5px;
    }
}

// ---------------LOGIN---------------------//

// ---------------DASHBOARD---------------------//

header {
    box-shadow: 0 2px 6px #0000000a;
    position: fixed;
    z-index: 111;
    width: 100%;
    background: #fff;
    top: 0;
}

.ms-Icon {
    font-weight: 700;
    color: $purple;
}

.StatusCircleQuestionMark {
    .ms-Icon {
        font-size: 24px;
    }
}

.dashboard-wrp {
    margin-top: 65px;

    @media (max-width: 639px) {
        margin-top: 55px;
    }
}

.paddingCmn {
    padding: 25px 0px;
}

.logo-wrp {
    width: 140px;

    @media (max-width: 639px) {
        width: 90px;
    }
}

.header-wrp {
    padding: 13px 0px 16px 0px;

    .rightMenu {
        display: flex;
        justify-content: flex-end;
    }
}

.userThumb {
    figure {
        position: relative;
        height: 35px;
        width: 35px;

        .ms-Image {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            overflow: hidden;
        }

        span {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #1bce33;
            position: absolute;
            right: 1px;
            bottom: 3px;
        }
    }
}

.notificationBtn {
    .ms-Button-textContainer {
        position: absolute;
        font-size: 8px;
        top: 4px;
        right: 5px;
        color: #ffff;
        background: #0181ae;
        border-radius: 100px;
        width: 13px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
}



.sidemenu-wrp {

    width: 260px;

    ::-webkit-scrollbar {
        width: 7px;
    }

    ::-webkit-scrollbar-track {
        background-color: white;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgb(199, 199, 199);
    }

    .ms-FocusZone {
        position: fixed;
        width: 260px;
    }

    nav {
        border: none;
        padding-right: 5px;
        height: 85vh;
        overflow-y: scroll;

        .ms-Button-icon {
            color: #0181ae;
            margin-right: 15px;
        }
    }

    .ms-Nav-compositeLink {
        .ms-Nav-link {
            border-radius: 0;
            padding: 0 0px 0 20px;
            border: none;
            height: 50px;
            font-size: 15px;
            font-weight: 400;

            &:hover {
                color: #0181ae;

                .ms-Button-icon {
                    color: #f29101;
                }
            }
        }

        ~.ms-Nav-navItems {
            .ms-Nav-link {
                padding: 0 0px 0 60px;
            }
        }
    }

    .is-selected {
        .ms-Nav-link {
            background-color: #0181ae0d;

            &:after {
                border-left: 5px solid #0181ae;
            }
        }
    }

    .ms-Nav-compositeLink.is-expanded {
        .ms-Nav-link {
            background-color: #0181ae0d;

            &:after {
                border-left: 5px solid #0181ae;
            }
        }
    }

    .ms-Nav-chevronButton {
        right: 0;
        left: auto;
    }
}

.mainContent-wrp {
    width: calc(100% - 260px);
    background: #f9fafb;
}

.mainContent-inn {
    padding: 0 25px;
}

.userWelcomebox {
    padding: 0px 25px;
    background: url("../assets/dashboard_bg.png") no-repeat;
    background-position: center;
    background-size: 100% 100%;

    .userWelcomeboxLeft {
        display: flex;
        align-items: center;

        h3 {
            font-size: 24px;
            color: rgb(0, 71, 146);
            font-weight: 700;
        }

        h4 {
            font-size: 26px;
            color: $purple;
            font-weight: 450;
        }

        figure {
            width: 85px;
            height: 85px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 15px;
        }
    }
}


.partnerSelect {
    display: flex;
    align-items: center;

    .cxBOx {
        font-size: 18px;
        color: #ffffff;
        font-weight: 500;
        background-color: #0181ae;
        border-radius: 2px;
        height: 40px;
        padding: 0px 10px;
        vertical-align: top;
        margin-right: 5px;
        display: flex;
        align-items: center;
    }

    button {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 40px;
    }
}

.totalSummary-box {
    box-shadow: 0 3px 6px #00000029;
    background-color: #ffffff;
    border-left: 9px solid $purple;
    margin-bottom: 20px;

    ul {
        display: flex;

        li {
            font-size: 15px;
            color: #303030;
            flex-grow: 1;
            flex-basis: 0;
            border-left: 1px solid #efefef;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 25px;
            min-height: 100px;
            position: relative;

            span {
                display: block;
                font-size: 30px;
                color: $purple;
                font-weight: 600;
                margin-top: 10px;
            }

            h6 {
                font-size: 20px;
                color: $purple;
                font-weight: 600;
            }

            .ms-Link {
                font-size: 13px;
                color: #0181ae;
                font-weight: 600;
                text-transform: uppercase;
                position: absolute;
                top: 10px;
                right: 14px;
            }
        }
    }
}

.dashboardHead {
    padding: 15px 0px;

    h2 {
        font-size: 30px;
        color: #0181ae;
        font-weight: 600;
    }
}

.search-wrp {
    display: flex;

    .ms-SearchBox {
        height: 38px;
        margin-right: 10px;
        border: 1px solid #000000;
        border-radius: 2px;
        width: 240px;

        @media (max-width: 639px) {
            width: 169px;
        }
    }

    .ms-SearchBox-icon {
        color: $purple;
    }
}

.modalBtnGrp {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    button {
        width: 49%;
        text-transform: uppercase;
    }
}

.filterModalBtnGrp {
    display: flex;
    justify-content: space-between;

    margin: 30px 0 0;

    button {
        width: 32%;
        text-transform: uppercase;
    }
}

.active-dot {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1px solid white;
    background-color: rgb(69, 209, 69);
    margin-bottom: 12px;
}

// TENANTS
.basicTab {
    .ms-Pivot-link {
        padding: 0;
        font-size: 16px;
        color: #8a8a8a;
        font-weight: 500;
        margin-right: 20px;
        margin-bottom: 10px;

        @media (max-width: 639px) {
            font-size: 14px;
            margin-right: 10px;
        }

        &:hover {
            background-color: #0181ae;
            color: #0181ae;
        }

        &:before {
            display: none;
        }

        &.is-selected {
            color: #0181ae;
        }
    }
}

.customTable {
    .ms-DetailsRow-cellCheck {
        .ms-DetailsRow-check {
            height: 100%;

        }
    }

    .ms-DetailsList-headerWrapper {
        .ms-FocusZone {
            background-color: #0181ae;
            padding-top: 4px;
        }

        .ms-DetailsHeader-cellName {
            color: #ffffff;
            font-size: 13px;
            font-weight: 500;
            font-family: "Poppins", sans-serif !important;
        }


        .ms-DetailsHeader-cell:hover {
            color: #ffffff;
            background: #f4f4f400;
        }
    }

    .ms-DetailsList-contentWrapper {
        .ms-FocusZone {
            background-color: #f6f3f7;
        }

        .ms-List-cell:nth-child(even) {
            .ms-FocusZone {
                background-color: #f9fafb;
            }
        }

        .ms-DetailsRow-cell {
            font-size: 13px;
            color: #303030;
            font-weight: 400;
            padding-top: 20px;
            padding-bottom: 20px;

        }

        .ms-DetailsRow-fields {
            align-items: center;
        }
    }

    .ms-Check-check {
        color: white !important;
    }
}

.noborder {
    .ms-Dropdown-title {

        font-size: 13px;
        color: #303030;
        font-weight: 400;
    }
}

.ms-Callout {
    width: auto;
}

.customCheckbox {
    padding: 5px 0;

    .ms-Checkbox-text {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 400;
    }
}

// TENANTS

// SESSIONS

.progressBar {
    .ms-ProgressIndicator-progressBar {
        background-color: #3bb54a;
    }

    &.redBar {
        .ms-ProgressIndicator-progressBar {
            background-color: #f14136;
        }
    }

    .ms-ProgressIndicator-itemDescription {
        color: #303030a6;
        font-size: 11px;
        font-family: "Poppins", sans-serif !important;
    }
}

.dropArea {
    border: 1px dashed #bbbbbb;
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;

    h6 {
        font-size: 12px;
        color: #201f1e;
        font-weight: 400;
    }
}

.dropArea-emb {
    border: 1px dashed #bbbbbb;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;

    h6 {
        font-size: 12px;
        color: #201f1e;
        font-weight: 400;
    }
}

.uploaBtn-wrp {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-bottom: -3px;
    margin-left: 3px;

    .btn {
        cursor: pointer;
        color: #0078d4;
        background-color: white;
        font-size: 12px;
        border: none;
        padding: 0;
        border-bottom: 1px solid #0078d4;
    }

    input[type="file"] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}

// SESSIONS
// SUMMARY

.summary-wrp {
    h4 {
        font-size: 18px;
        color: #303030;
        font-weight: 600;
        margin-bottom: 25px;

        @media (max-width: 1023px) {
            font-size: 15px;
        }

        @media (max-width: 639px) {
            font-size: 14px;
        }
    }
}

.summaryDetailbox {
    position: relative;
    padding: 15px 5px 15px 15px;
    background-color: #ffffff;
    box-shadow: 0 3px 6px #00000029;
    margin-bottom: 30px;

    h3 {
        font-weight: 600;
        margin-bottom: 5px;
    }

    p {
        font-size: 13px;
        color: #2e2d2d;
    }

    h6 {
        font-size: 15px;
        color: #303030;
        margin-bottom: 20px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        font-size: 12px;
        color: #2e2d2db3;
        font-weight: 400;
        width: 50%;

        @media (max-width: 1023px) {
            width: 100%;
            margin-bottom: 10px;
        }

        span {
            font-size: 15px;
            color: #303030;
            font-weight: 600;
            display: block;
            margin-bottom: 7px;
        }
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: #0181ae;
        box-shadow: 0 3px 6px #00000029;
        width: 10px;
        height: 100%;
    }

    .text-right {
        @media (max-width: 639px) {
            text-align: left;
        }
    }
}

.Tstatus {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.registerLink {
    font-size: 14px;
    color: #0078d4;
    font-weight: 400;
    padding: 0;
    text-decoration: underline;
    background: none;
    text-transform: capitalize;
}

.registerPlanleft {
    height: 100%;
    background-color: #f6f6f6;
    padding: 20px;

    h5 {
        font-size: 14px;
        color: #303030;
        font-weight: 600;
    }

    p {
        font-size: 12px;
        color: #303030;
        font-weight: 400;
        margin-bottom: 30px;
        margin-top: 4px;
    }
}

.ms-ContextualMenu-Callout {
    .ms-ContextualMenu-link {
        padding: 0;
    }

    .ms-FocusZone {
        background-color: #f6f3f7;
        min-width: 140px;
    }

    .ms-ContextualMenu-linkContent {
        .ms-ContextualMenu-icon {
            color: #0181ae;
        }
    }

    .ms-ContextualMenu-linkContent:hover {
        background-color: #0181ae0d !important;
    }

    .ms-ContextualMenu-itemText {
        font-size: 12px;
        color: #000000;
        font-weight: 500;
    }

    .ms-Callout-beak {
        background-color: #f6f3f7;
    }
}

.actionBtn {
    min-width: 140px;

    .ms-Icon {
        display: none;
    }
}

// SUMMARY

// REGISTRATION

.filterBtn {
    position: relative;

    .ms-Button-textContainer {
        position: absolute;
        font-size: 8px;
        top: -7px;
        right: -4px;
        color: #ffff;
        background: #0181ae;
        border-radius: 100px;
        width: 13px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
}

.filterModal {
    .modalBtnGrp {
        margin-top: 0;
        padding: 20px 15px;
        border-top: 1px solid #f4f4f4;
        justify-content: initial;

        button {
            width: auto;
            margin-right: 5px;
        }

        .registerLink {
            margin-left: auto;
            margin-right: 0px;
        }
    }
}

.filter-wrp {
    display: flex;
    flex-wrap: wrap;
    min-height: 390px;

    .search-wrp .ms-SearchBox {
        margin-right: 0;
        width: 100%;
        border: 1px solid #c0c0c0;
    }
}

.filterleft {
    width: 200px;
    border-right: 1px solid #d6d6d6;
    max-height: 390px;
    overflow-y: auto;

    li {
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #d6d6d6;
        padding: 15px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 30px;

        &.active {
            background-color: #0181ae24;
            border-left: 5px solid #0181ae;
        }

        span {
            font-size: 12px;
            color: #ffff;
            background: #0181ae;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
        }
    }
}

.filterRight {
    width: calc(100% - 200px);
    padding: 10px;

    .collapsible {
        display: none;

        &.open {
            display: block;
        }
    }

    .checkbox-wrp {
        padding: 15px 15px;
        max-height: 320px;
        overflow-y: auto;

        .ms-Checkbox {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .ms-Checkbox-text {
            font-size: 12px;
        }
    }
}

// REGISTRATION

// ---------------DASHBOARD---------------------//

// @media (max-width: 1919px) {
// }
@media (max-width: 1550px) {
    .userWelcomebox .userWelcomeboxLeft h3 {
        font-size: 22px;
    }

    .userWelcomebox .userWelcomeboxLeft h4 {
        font-size: 20px;
    }

    .totalSummary-box ul li span {
        font-size: 28px;
        margin-top: 6px;
    }

    .totalSummary-box ul li {
        padding: 20px 20px;
        min-height: 75px;
    }
}

// @media (max-width: 1365px) {
// }

@media (max-width: 1023px) {
    .sidemenu-wrp {
        width: 200px;

        .ms-FocusZone {
            width: 200px;
        }
    }

    .mainContent-wrp {
        width: calc(100% - 200px);
    }

    .totalSummary-box ul {
        display: flex;
        flex-wrap: wrap;
    }

    .totalSummary-box ul li {
        flex-grow: unset;
        flex-basis: unset;
        width: 50%;
        padding: 15px 15px;
    }

    .dashboardHead h2 {
        font-size: 20px;
    }
}

@media (max-width: 639px) {
    .form-wrp {
        position: absolute;
        background: #fffc;
        height: 100%;
    }

    .sidemenu-wrp {
        width: 200px;
        position: fixed;
        z-index: 1;
        top: 51px;
        height: 100vh;
        background: #fff;
        left: -200px;

        &.open {
            left: 0;
        }

        .ms-FocusZone {
            width: 100%;
            position: initial;
        }
    }

    .mainContent-wrp {
        width: 100%;
    }


    .totalSummary-box ul li span {
        font-size: 20px;
        margin-top: 3px;
    }

    .totalSummary-box ul li {
        padding: 10px 10px;
        min-height: auto;
        width: 100%;
    }

    .dashboardHead .cmnBtn {
        font-size: 11px;
        margin-top: 25px;
        padding: 0;
    }

    .dashboardHead .search-wrp .ms-SearchBox {
        width: 176px;
    }

    .userWelcomebox .userWelcomeboxLeft figure {
        width: 60px;
        height: 60px;
        margin-right: 10px;
    }

    .userWelcomebox .userWelcomeboxLeft h3 {
        font-size: 16px;
    }

    .userWelcomebox .userWelcomeboxLeft h4 {
        font-size: 14px;
    }

    .partnerSelect {
        margin-top: 6px;
    }

    .modalHeader {
        font-size: 18px;
    }

    .dashboardHead {
        padding: 15px 0px;
    }

    .paddingCmn {
        padding: 25px 0px;
    }

    .dashboardHead h2 {
        font-size: 16px;

        .ms-Icon {
            font-size: 12px;
        }
    }

    .customTable .ms-DetailsList-contentWrapper .ms-DetailsRow-cell {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .filter-wrp {
        min-height: auto;
        max-height: 300px;
    }

    .filterleft li {
        font-size: 12px;
        padding: 10px 5px;
        padding-left: 10px;
    }

    .filterleft {
        width: 120px;
        max-height: 268px;
    }

    .filterRight {
        width: calc(100% - 120px);
        padding: 5px;
    }

    .filterleft li span {
        font-size: 10px;
        height: 16px;
    }

    .filterRight .checkbox-wrp {
        padding: 6px 6px;
        max-height: 220px;
    }

    .filterRight .checkbox-wrp .ms-Checkbox-text {
        font-size: 11px;
    }

    .filterRight .checkbox-wrp .ms-Checkbox {
        margin-bottom: 5px;
    }

    .ms-Checkbox-checkbox {
        height: 15px;
        width: 15px;
    }

    .filterModal .modalBtnGrp {
        padding: 12px 6px;
    }
}

// @media (max-width: 479px) {
// }
button:hover .ms-Button-icon {
    color: #0181ae !important;
}

/* Table Layout */
.tableRowAsnSearch {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
  }
  
  .tableRowAsnSearch th {
    background: #0181ae; /* Background color for header */
    color: #fff; /* Text color for header */
    font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
    padding: 8px;
    border-bottom: 1px solid rgb(234, 234, 234);
    text-align: left;
    vertical-align: top;
    cursor: default;
    user-select: none;
    white-space: nowrap;
  }
  
  .asn-csv-rows {
    font-size: 13px;
    padding: 15px 8px; /* Adds spacing around cell content */
    color: #000; /* Explicit black color for table rows */
    background: #fff; /* White background for rows */
  }
  
  /* Responsive Behavior */
  @media (max-width: 768px) {
    .tableRowAsnSearch th, .tableRowAsnSearch td {
      font-size: 11px;
      padding: 6px;
    }
  }
  
  @media (max-width: 480px) {
    .tableRowAsnSearch, .tableRowAsnSearch th, .tableRowAsnSearch td {
      font-size: 10px;
      padding: 4px;
    }
  }
  

.login-form {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);
}

textarea:focus-visible {
    outline: 1px solid #0181ae;
    border-color: #0181ae;
}

.table-mapping,
th,
td {
    border: 1px solid #dee2e6;
}

.supplier-addmore>span>i.ms-Icon {
    font-size: 30px;
    padding: 0;

}

.supplier-remove>span>i.ms-Icon {
    font-size: 30px;
    padding: 0;
    color: red;
}

.supplier-addmore>span> :hover {
    font-size: 30px;
    padding: 0;

}

.supplier-remove>span> :hover {
    font-size: 30px;
    padding: 0;
    color: red !important;
}

.view-job-card {
    display: flex;
    flex-direction: row;
    width: 1000px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.job-card-row {
    display: flex;
    padding-top: 7px;
    padding-bottom: 7px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .uploadFileInput {
        align-items: center;
        width: 200px;
    }

    .value-field {
        color: grey;
        background-color: rgb(244, 244, 244);
        border: 1px solid rgb(244, 244, 244);
        font-size: 14px;
        padding: 3px 10px;
    }
}

.job-card-row>span {
    align-items: center;
    width: 200px;
}

.edit-job-field {
    margin-bottom: 0;
}

.show-asset-file {
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

@media print {
    @page {
        size: landscape !important;
        margin: 0;
    }

    body {
        margin: 1.6cm;
    }

    .back-btn {
        display: none;
    }

    .pagebreak {
        clear: both;
        page-break-after: always;
    }

    .ct-toast {
        display: none;
    }

    .print-card {
        margin-top: 4%;
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .print-card-row {
        font-size: x-large;
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 4px;
    }

    .print-card-item {
        width: 42%;
        font-size: 100%;
    }

    .left {
        display: flex;
        justify-content: flex-end;
    }
}

@media screen {
    .print-area {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 40px;
    }

    .pagebreak :last-child .print-area {
        padding-bottom: 40px;
    }

    .print-card {

        height: 700px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid;
    }

    .print-card-row {
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 6px;
    }

    .print-card-item {
        width: 46%;
        font-size: x-large;
    }

    .left {
        display: flex;
        justify-content: flex-end;
    }
}

.job-action>div {
    display: flex;
    justify-content: space-evenly;
}

.job-action>div>button {
    width: 100%;
    max-width: 130px;
    margin: 0 5px 0 5px;
    height: 40px;
}

.job-list-table {
    .ms-DetailsHeader-cellTitle {
        display: flex;
        justify-content: space-evenly;
    }
}

.view-job-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0 32px 0;
}

.view-job-btn-left {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.view-job-btn-right {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.view-job-btn-right>button {
    width: 44%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 10px;
}

.view-container-card {
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}

.portfolio-filter-dropdown-option {
    width: 0%;

    .ms-Dropdown {
        outline: transparent;

        .ms-Dropdown-title {
            color: transparent;
            border: transparent;
        }

        .ms-Dropdown-caretDownWrapper {
            display: none;
        }
    }
}

.portfolio-filter-dropdown-option::after {
    border: none !important;
}

.container-card-row {
    width: 100%;
    display: flex;
    padding: 10px 0 10px 0;
    align-items: center;
    justify-content: space-between;

    .inputBox {
        width: 47%;
    }

    .datePickerBox {
        display: flex;
        flex-direction: row;
        align-items: center;

        .ms-TextField {
            width: 300px;
        }
    }

    .inputBox label {
        margin-bottom: 5px;
    }

    .inputBox input {
        align-self: center;
    }
}

.container-btn-grp {
    width: 100%;
    display: flex;
    margin-top: 32px;
    justify-content: space-between;
    align-items: center;

    .btn-right {
        width: 70%;
        display: flex;
        justify-content: space-between;
    }

    .btn-right>button {
        width: 32.899%;
    }
}

.column-align-center {
    align-items: center;
    text-align: center;
}

.view-container-btns {
    display: flex;
    justify-content: space-between;
}

.share-report {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.companyLogo {
    height: 40px;
}

.companyLogoLabel {
    width: 160px;
    max-height: 80px;
    border-radius: 10%;
    object-fit: contain;
    margin-bottom: 20px;
}

.inputRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .inputBox {
        width: 48%;
    }
}

.autocompleteinputcnt {
    width: 100%;
    padding: 4px 10px;
    box-sizing: border-box;
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
}

.autocompleteinputcnt:focus-visible {
    border: none;
}

.autocompleteinputcnt:focus {
    border: 2px solid #0181ae !important;
}

.autodroplist {
    padding: 10px;
}

.autodroplist:hover {
    color: rgb(1, 129, 174);
    cursor: pointer;
}

.container-date {
    margin: 0px 0px 13px;
}

.container-date .ms-TextField-fieldGroup {
    min-height: 41px;
}

.container-date .msDatePickerDisabled {
    margin-top: 3px;
}

#autodivremove>div>div {
    padding: 0px !important;
}

.ham-menu {
    display: inline-block;
    position: relative;
    margin: -15px 0;
}

.ham-menu,
.ham-menu::before,
.ham-menu::after {
    width: 33px;
    height: 5px;
    border-radius: 1px;
    background-color: #0181ae;
}

.ham-menu::before,
.ham-menu::after {
    content: "";
    display: block;
    position: absolute;
}

.ham-menu::before {
    bottom: 130%;
}

.ham-menu::after {
    top: 130%;
}

.portfolio-filter-dropdown-option #dropdown-display-options-option {
    background-color: transparent !important;
    border: none !important;
}

.portfolio-filter-dropdown-option #dropdown-display-options-option::after {
    border: none !important;
}

.portfolio-filter-dropdown-option #dropdown-display-options:focus::after {
    border-color: transparent !important;
}

.file-input {
    border: 1px solid black;
    border-radius: 2px;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;
    padding: 0 10px 0 10px;
}

.file-input-text {
    display: flex;
    align-items: center;
}

// Updated on 02-02-2022 As per the QA suggestion
.list-col-center .ms-DetailsRow-cell {
    display: flex;
    justify-content: space-evenly;
}

.list-col-center div.ms-DetailsHeader-cell span:nth-child(1) {
    justify-content: center !important;
}

// End updated

.prod-detail-wrapper {
    display: flex;
    flex-direction: row;
    width: 1000px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-bottom: 10px;
    margin-top: 10px;

    .prod-detail {
        width: 400px;
    }

    .prod-detail-label-wrap {
        color: grey;
        background-color: rgb(244, 244, 244);
        border: 1px solid rgb(244, 244, 244);
        padding: 3px 10px;
        margin-top: 10px;
    }
}

.label-danger label {
    color: red;
}

.userThumb2 {
    span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        top: 32px;
    }

    .correction-yes {
        background-color: #ce1b1b;
    }
}

// theme css modification
.css-35 {
    overflow: visible !important;
}

.spinner-icon {
    animation: paragonmove 1s infinite linear;
}

@keyframes paragonmove {
    100% {
        transform: rotate(360deg);
    }
}

.dropAreaMulti-emb {
    border: 1px solid #000000;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;

    h5 {
        font-size: 12px;
        color: #201f1e;
        font-weight: 400;
        margin-bottom: 2px !important;
    }
}

.dropAreaMulti h5 {
    font-size: 12px;
    color: #201f1e;
    font-weight: 400;
    margin-bottom: 0px !important;
}

*,
*::before *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.list {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;

    text-align: left;
    justify-content: space-around;

}

.list .list-link {
    position: relative;
    color: #000000;
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

}

.light {
    height: 8px;
    width: 8px;
    background-color: #1bce33;
    border-radius: 50%;
    position: absolute;
}

//style for Support document - Start
.support-docs {
    padding-left: 410px !important;
    padding-top: 100px !important;
}

.support-docs-list {
    list-style: none;
    font-size: 12px;
    color: red;
    font-style: italic;
}

.support-docs-complete-list {
    list-style: none;
    font-size: 12px;
    color: #0181ae;
}

.support-doc-uppper-tr {
    border-width: 1px 1px 0px 0.6px;
    border-color: #c9bbcf;
    border-style: solid;
}

.support-doc-lower-tr {
    border-width: 0px 1px 1px 0.6px;
    border-color: #c9bbcf;
    border-style: solid;
}

.error-btn-support-doc {
    font-size: 12px;
    color: #000000;
    border: 1px solid #000000;
    font-weight: 400;
    border-radius: 2px;
    height: 38px;
    margin-top: 25px;

    &:disabled {

        border: 1px solid #bebbb8;
        color: #bebbb8;
    }
}

//style for Support document - End

//  table style

.table-style {

    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;

    td,
    th,
    tr {

        text-align: left;
        padding: 10px;
    }

    .table-footer {
        text-align: center;
        background-color: aliceblue;
    }
}

.sfecomparemodal .ms-Dialog-main {

    width: auto;

    .ms-Modal-scrollableContent {
        overflow-y: hidden !important;
        flex-grow: 1;
        max-height: 100vh;
    }
}

.borderLinechange {
    margin-bottom: 12px;

    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-TextField-fieldGroup {
        border: 1px solid #ff1e00;
        border-radius: 2px;
        min-height: 42px;
    }

    .ms-Dropdown-title {
        border: 1px solid #ff1e00;
        border-radius: 2px;
        height: 42px;
        padding: 5px 28px 0px 8px;
    }

    .ms-Dropdown {
        width: 100%;
    }

    .ms-Dropdown-caretDownWrapper {
        top: 7px;
    }

    .ms-TextField-prefix {
        background: #f4f4f487;
    }
}

.rm-icon-bg:active,
.rm-icon-bg:hover {
    background-color: unset;
}

.hover_underline:hover {
    text-decoration: underline;
}

.datepicker {
    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-TextField-fieldGroup {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px;
        width: 150px;
    }

    .ms-DatePicker-title {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px !important;
        line-height: 36px !important;
    }

    .ms-DatePicker {
        width: 160px;
    }

    .ms-DatePicker-caretDownWrapper {
        top: 0px;
        line-height: 40px !important;

    }

    .ms-TextField-prefix {
        background: #f4f4f487;
    }

}

.search-Box {
    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-TextField-fieldGroup {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px;
        width: 150px;
    }

    .ms-SearchBox-title {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px !important;
        line-height: 36px !important;
    }

    .ms-SearchBox {
        width: 180px;
        height: 38px;

        border: 1px solid;
    }

    .ms-SearchBox-caretDownWrapper {
        top: 0px;
        line-height: 40px !important;
    }

    .ms-TextField-prefix {
        background: #f4f4f487;
    }

}

.scroll-Parent {
    .ms-Viewport {
        height: calc(100vh - 250px);
        overflow-y: auto;

        .ms-DetailsList {
            height: 100%;

            .grid {
                height: 100%;

                .headerWrapper {
                    position: sticky;
                    top: 0;
                }
            }
        }
    }
}

.Dropdown-search {
    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-Dropdown-fieldGroup {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px;
        width: 150px;
    }

    .ms-Dropdown-title {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px !important;
        line-height: 36px !important;
    }

    .ms-Dropdown {
        width: 200px;
        height: 38px;

    }

    .ms-Dropdown-caretDownWrapper {
        top: 0px;
        line-height: 40px !important;

    }

    .ms-TextField-prefix {
        background: #f4f4f487;
    }

}

.dropdownOptionText-187 {
    text-overflow: clip;
    white-space: nowrap;
    padding-right: inherit;
}

.flexContainer-122 {
    min-width: 225px !important;
}

.table-sm {
    border-collapse: collapse;
    width: 100%;

    .td,
    tr {
        border: 1px solid #3c4048;
    }

    tr:nth-child(even) {
        background-color: #f6f3f7;
    }
}

.inputSuggestion {
    width: 280px;
    border: 1px solid #000000;
    border-radius: 2px;
    height: 35px;
    padding: 10px;
}

.inputSuggestion:focus {
    outline: 1px solid #0181ae !important;
    border-color: #0181ae;
}


.view-all {
    padding-left: 0px;
    margin-top: 10px;
}

.cards-section .individual-card h1 {
    font-size: 12px;
    font-family: "Roboto Light", sans-serif;
}

.cards-section li {
    list-style: none;
}

/*Full Card Section */
.cards-section {
    display: flex;
    flex-wrap: wrap;
}

/*Individual Card*/
.cards-section .individual-card {
    width: calc(100% / 4 - 20px);
    min-width: 345px;
    margin: 10px;
}

.cards-section .individual-card .cardwrap {
    width: 100%;
    overflow: hidden;
    border: 1px solid rgba(1, 120, 200, 0.2);
}

.cards-section .individual-card .cardwrap:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
}

.cards-section .card-header {
    display: flex;
    justify-content: space-between;
    background-color: #0181ae;
    padding: 20px;
}

.cards-section .mpan,
.mprn {
    row-gap: 5px;
    display: flex;
    flex-direction: column;
    color: white;
}



.cards-section .mpan b,
.mprn b {
    color: #9ff2ff;
}

.cards-section .mpan span,
.mprn span {
    font-weight: bold;
}

.cards-section .card-content {
    display: flex;
    flex-direction: column;
    row-gap: 11px;
    padding: 20px;
}

.cards-section .card-table {
    width: 100%;
    text-align: left;
    display: flex;
    border: 3px solid #e3e3e3;
}

.cards-section .card-table li {
    width: calc(100% / 2);
}

.cards-section .card-table h1 b {
    margin-bottom: 2px;
    color: #2a6d97;
    display: block;
}

.cards-section .card-table h1 span {
    font-weight: bold;
}

.cards-section .card-table h1 {
    color: black;
}

.cards-section .card-table li:first-child {
    text-align: left !important;
    border-right: 1px solid #e3e3e3;
    padding: 15px;
}

.cards-section .card-table li:last-child {
    padding: 15px;
    text-align: right;
}

/*Line*/
.cards-section .card-content-line {
    height: 1px;
    background-color: #e3e3e3;
}

.cards-section .communication,
.ihd {
    row-gap: 5px;
    display: flex;
    flex-direction: column;
}

.cards-section .ihd b,
.communication b {
    color: #9b9b9b;
    margin-bottom: 2px;
}

.cards-section .ihd span,
.communication span {
    font-weight: bold;
}

.cards-section .ihd,
.communication {
    color: #000000;
}

.ihd span {
    margin-left: 4px;
}

/*Responsive*/
@media (max-width: 1460px) {
    .cards-section .individual-card {
        width: calc(100% / 3 - 20px);
    }
}

@media (max-width: 1095px) {
    .cards-section .individual-card {
        width: calc(100% / 2 - 20px);
    }
}

@media (max-width: 730px) {
    .cards-section .individual-card {
        width: calc(100% - 20px);
    }
}

.mpxn {

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
}

.search-Box-meterpoint {
    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-TextField-fieldGroup {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px;
        width: 250px;
    }

    .ms-SearchBox-title {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px !important;
        line-height: 36px !important;
    }

    .ms-SearchBox {
        width: 280px !important;
        height: 38px;
        border: 1px solid;
    }

    .ms-SearchBox-caretDownWrapper {
        top: 0px;
        line-height: 40px !important;
    }

    .ms-TextField-prefix {
        background: #f4f4f487;
    }

}

.ms-Button-label .label-65 {
    margin: 0px 4px;
    line-height: 100%;
    display: block;
    font-size: 14px;
    font-weight: 350;
    color: rgb(133 133 133);
}

.toggle-btn {
    margin-left: 15px;
    margin-top: 5.5px;
}



.efd .ms-TextField-fieldGroup {
    height: 38px !important;
}

.calendar_height .ms-Button {
    height: 38px !important;
    width: 150px !important;
}

.filter-rt {

    margin-right: 0;
}

.toggle-btn .ms-Toggle:not(.is-checked) {
    .ms-Toggle-background {
        border: 2px solid #0181ae;

        .ms-Toggle-thumb {
            background-color: #0181ae;
        }
    }
}

.toggle-btn .ms-Toggle.is-checked {
    .ms-Toggle-background {
        border: 2px solid #0181ae;
        background: transparent;

        .ms-Toggle-thumb {
            background-color: #0181ae;
        }
    }
}

.search-wrp-cos {
    display: flex;

    .ms-SearchBox {
        height: 38px;
        margin-right: 10px;
        border: 1px solid #000000;
        border-radius: 2px;
        width: 200px;

        @media (max-width: 639px) {
            width: 169px;
        }
    }

    .ms-SearchBox-icon {
        color: $purple;
    }
}

.tl-dtpick {
    .ms-TextField-fieldGroup {
        height: 35px;
        width: 120px;
    }
}

.fnc-muls {
    margin-top: 30px;
    width: 60%;
}

.file-err-hist {

    left: 60%;
    margin-top: -50%;
    padding: 10px 10px 10px 10px;

}

.auto-sug-list {
    width: 100% !important;
    z-index: 10 !important;
    background: white !important;
}

.rm-top-pd {
    margin-top: 5px !important;
}

.currentDetailsSearch {
    position: relative;

    .menu {
        position: absolute;
        box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
    }

    .auto-sug-list.col {
        padding: 5px 10px;
    }
}

.back-btn-mrg {
    position: absolute;
    margin-top: 0px;
    padding-bottom: 40px;
    margin-left: 15px;
}

.marg-top {
    margin-top: 80px;
}

.view-heder-detailes {
    font-size: 14px;
    font-weight: 450;
    display: flex;
    flex-direction: column;
}

.export-doc {
    float: right;
    margin-top: -5px;
}

.export-doc-btn {
    border: none;
    color: #0181AE;
    background-color: transparent !important;
    padding: 0;
}

.no-file-text {
    text-align: center;
    padding-top: 15px;
}

.drpdwn-mrg-left {
    margin-left: 15px;
}

.pt-15 {
    padding-top: 15px;
}

.mg-btm-10 {
    margin-bottom: 10px;
}

.err-msg {
    margin-top: 40px;
    text-align: center;
}

.dropArea-reports {
    border: solid 1px #000000;
    height: 130px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;

    h6 {
        font-size: 14px;
        color: #201f1e;
        font-weight: 400;
    }

    p {
        font-size: 12px;
        margin-top: -2%;
    }
}

.multid {
    width: 210px;
    height: 38px;
    font-size: 13px;
    font-weight: 400;
    font-family: $font1;
    border: 1px solid #000000;
    border-radius: 2px;
    text-align: center;
}

.triage-search-wrp {
    display: flex;

    .ms-SearchBox {
        height: 38px;
        margin-right: 10px;
        border: 1px solid #000000;
        border-radius: 2px;
        width: 180px;

        @media (max-width: 639px) {
            width: 169px;
        }
    }

    .ms-SearchBox-icon {
        color: $purple;
    }
}

.triage-inputBoxFilter {
    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-TextField-fieldGroup {
        border: 1px solid #000000;
        border-radius: 2px;
    }

    .ms-Dropdown-title {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px !important;
        line-height: 36px !important;
    }

    .ms-Dropdown {
        width: 180px;
    }

    .ms-TextField-prefix {
        background: #f4f4f487;
    }

    .ms-Dropdown-caretDownWrapper {
        line-height: 40px !important;
        top: 0px;
    }
}

// design update
.folder-block-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 15px 20px;

    @media (max-width: 1367px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 1199px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 520px) {
        grid-template-columns: 1fr;
    }

    .folder-block {
        position: relative;

        .custom-dropdown {
            display: inline-block;
            position: absolute;
            right: 8px;
            bottom: 10px;
            top: unset;
            margin: auto;
            padding: 0;
        }
    }

    .primary-folder {

        padding: 10px;
        border: 1px solid #d7d7d7;
        background: #efefef;
        cursor: pointer;

        .img-block {
            margin-bottom: 10px;

            &.img-center {
                img {
                    display: block;
                    margin: auto;
                    text-align: center;
                }
            }

            img {
                width: 70px;
            }
        }

        .txt-block {
            position: relative;

            h4 {
                font-size: 16px;
                color: $purple;
                font-weight: 600;
                margin: 0;
                white-space: nowrap;
                width: 140px;
                height: 21px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span {
                font-size: 12px;
                color: #303030;
                font-weight: 400;
            }
        }
    }
}

.custom-top-right {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    margin: auto;
    right: 0;
    top: 0;
    padding: 0;

    .notification {
        border: none;
        padding: 0;
        border-radius: 2px;
        background-color: transparent;
    }
}




.custom-dropdown {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    padding: 0;

    .dropdown-btn {
        border: none;
        padding: 10px;
        width: 40px;
        background-color: transparent;

        img {
            display: block;
            margin: auto;
            margin-right: 0;
        }
    }

    .btn-drp {
        cursor: pointer;
        border: 0px solid #000000;
        background-color: transparent;
        color: #000000;
        padding: 5px 16px;
        text-decoration: none;
        display: block;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid #f3f3f3;

        img {
            width: 12px;
            margin-right: 10px;
        }
    }

    .dropdown-list {
        position: absolute;
        min-width: 140px;
        z-index: 1;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        box-shadow: 0px 0px 46px 2px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        right: 0;

        a {
            color: black;
            padding: 5px 16px;
            text-decoration: none;
            display: block;
            font-size: 14px;
            font-weight: 400;
            border-bottom: 1px solid #f3f3f3;

            &:hover {
                background: #FAFAFA;
                text-decoration: none;
                color: #000;
            }

            &.delete-link {
                color: #FF0000;

                &:hover {
                    color: #d00606;
                }
            }

            img {
                width: 12px;
                margin-right: 10px;
            }
        }
    }
}

.generate-link {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 15px;
}

.job-action-icons>div {
    display: flex;
    justify-content: space-around;

}

.delte-Button {
    .ms-Icon {
        color: red !important;
    }

    .ms-Icon:hover {
        color: red !important;
    }
}

.addUserModal-external .ms-Dialog-main {
    width: auto;
}

.notification-view {
    font-size: 11px;
    font-weight: 600;
    color: #bea4a4;
    padding: 5px;
}

.job-action-icon2>div {
    display: flex;
    justify-content: initial;
}

.action-header-list>span>span>span {

    color: #a49f9f;
    padding: 3px;
    margin-left: 10px;
}

.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #D1D1D1;
    border-radius: 1px;

    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
}

.react-tags.is-focused {
    border-color: #B1B1B1;
}

.react-tags__selected {
    display: inline;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background: #F1F1F1;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: #B1B1B1;
}

.react-tags__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
}

@media screen and (min-width: 30em) {

    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }

}

.react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__search-input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: relative;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
}

@media screen and (min-width: 30em) {

    .react-tags__suggestions {
        width: 350px;
    }

}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

.modalBtnGrpSharebyMe {
    display: flex;
    justify-content: space-between;

    button {
        width: 49%;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
}

.action-header-ex>span>span>span {
    color: #a49f9f;
    padding: 3px;
    margin-left: 30px;
}

.preview-block-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px 35px;

    @media (max-width: 1367px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 1199px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 520px) {
        grid-template-columns: 1fr;
    }

    .folder-block {
        position: relative;
    }

    .primary-folders {
        padding: 10px;
        border: 1px solid #d7d7d7;
        background: #efefef;

        .img-block {
            margin-bottom: 10px;

            &.img-center {
                img {
                    display: block;
                    margin: auto;
                    text-align: center;
                }
            }

            img {
                width: 70px;
            }
        }

        .txt-block {
            position: relative;

            h4 {
                font-size: 14px;
                color: black;
                font-weight: 400;
                margin: 0;
                white-space: nowrap;
                width: 200px;
                height: 21px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span {
                font-size: 12px;
                color: #303030;
                font-weight: 400;
            }
        }
    }
}

.previewBtnGrp {
    margin-left: 26%;
    margin-bottom: 20px;
    margin-top: 10px;
}

.instamodalBtnGrp {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    button {
        width: 75%;
    }
}


.dzu-previewFileName {
    text-indent: -9999px;
    height: 45px;
    width: 52px;
    white-space: nowrap;
    margin-left: -2px;
    background-image: url("../assets/excelcsvicon.png")
}

.revamp-btn {
    display: flex;

    margin-top: -20px;
    margin-left: 120px;

    button {
        width: 86%;
    }
}


.install_link {
    color: #808080;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #808080;
}

.paddingCmn_install {
    padding: 10px 0px;
}

.primaryDoc_install {
    background-color: #a7eef929;
    padding: 5px 14px;
    margin-top: 10px;
}

.viewQuery_install {
    background-color: #cce9ee29;
    padding: 5px 14px;
    margin-top: 5px;
}

/////////////////////////

.paddingCmn-wrap {
    width: 100%;
    margin: auto;

    .paddingCmn-tbls-duplic {
        table-layout: fixed;
        width: 100%;
        border-spacing: 0 2px;
        border-collapse: separate;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        border: none;


        tr {
            background: #fff;
            border: none;
            box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 12%);
             border-radius: 10px 0px 0 10px;
            position: relative;

            td {
                border: none;
                padding: 5px 20px;
                color: #625e5e;
                font-size: 14px;

                &:first-child {
                    border-radius: 10px 0px 0 10px;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0;
                }

                b {
                    display: block;
                    color: #000;
                }
            }

            &.collapse-tr {
                background: transparent;
                box-shadow: none;

                td {
                    padding: 0;

                    .collapse-row {
                        display: flex;
                        margin-top: 0px;

                        .left-block {
                            flex: 1 1 350px;
                            background: #F1F6F8;
                            padding: 10px;
                        }

                        .right-block {
                            flex: 1 1 100%;
                            padding: 10px;
                            background: #ECF2F4;

                            ul {
                                list-style: none;
                                display: flex;
                                flex-wrap: wrap;
                                margin-bottom: 0px;
                                gap: 10px 0px;

                                li {
                                    width: calc(100% / 7);
                                }
                            }
                        }
                    }

                    .bg-list-tbl {
                        .date-list {
                            background: #16B3E4;
                            border-radius: 10px;
                            color: #fff;
                            display: flex;
                            list-style: none;
                            margin-bottom: 0px;
                            padding: 12px;
                            justify-content: center;

                            li {
                                margin-right: 10px;
                                padding-right: 10px;

                                &:first-child {
                                    border-right: 1px solid #fff;
                                }

                                &:last-child {
                                    margin: 0;
                                    padding: 0;
                                }

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }
                            }
                        }

                        .reference-list {
                            background: #003A52;
                            color: #fff;
                            border-radius: 10px;
                            list-style: none;
                            overflow: hidden;

                            li {
                                padding: 12px;
                                width: calc(100% / 4) !important;
                                padding-top: 15px;

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }

                                &.phone-items {
                                    background: #004F6F;

                                    b {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 5px;
                                        gap: 0 5px;

                                        span {
                                            font-size: 14px;
                                            word-break: break-all;
                                        }

                                        i {
                                            font-size: 20px;
                                            padding-right: 5px;
                                        }
                                    }
                                }

                                .ms-Icon {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .paddingCmn-tbls {
        table-layout: fixed;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 1em;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        border: none;


        tr {
            background: #fff;
            border: none;
            box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 12%);
            border-radius: 10px 0px 0 10px;

            td {
                border: none;
                padding: 5px 20px;
                color: #625e5e;
                font-size: 14px;

                &:first-child {
                    border-radius: 10px 0px 0 10px;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0;
                }

                b {
                    display: block;
                    color: #000;
                }
            }

            &.collapse-tr {
                background: transparent;
                box-shadow: none;

                td {
                    padding: 0;

                    .collapse-row {
                        display: flex;
                        margin-top: -10px;

                        .left-block {
                            flex: 1 1 350px;
                            background: #F1F6F8;
                            padding: 10px;
                        }

                        .right-block {
                            flex: 1 1 100%;
                            padding: 10px;
                            background: #ECF2F4;

                            ul {
                                list-style: none;
                                display: flex;
                                flex-wrap: wrap;
                                gap: 10px 0px;

                                li {
                                    width: calc(100% / 7);
                                }
                            }
                        }
                    }

                    .bg-list-tbl {
                        .date-list {
                            background: #16B3E4;
                            border-radius: 10px;
                            color: #fff;
                            display: flex;
                            list-style: none;
                            padding: 12px;
                            justify-content: center;

                            li {
                                margin-right: 10px;
                                padding-right: 10px;

                                &:first-child {
                                    border-right: 1px solid #fff;
                                }

                                &:last-child {
                                    margin: 0;
                                    padding: 0;
                                }

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }
                            }
                        }

                        .reference-list {
                            background: #003A52;
                            color: #fff;
                            border-radius: 10px;
                            list-style: none;
                            overflow: hidden;

                            li {
                                padding: 12px;
                                width: calc(100% / 4) !important;
                                padding-top: 15px;

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }

                                &.phone-items {
                                    background: #004F6F;

                                    b {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 5px;
                                        gap: 0 5px;

                                        span {
                                            font-size: 14px;
                                            word-break: break-all;
                                        }

                                        i {
                                            font-size: 20px;
                                            padding-right: 5px;
                                        }
                                    }
                                }

                                .ms-Icon {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .paddingCmn-style-tbls {
        table-layout: fixed;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 1em;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        border: none;


        tr {
            background: #fff;
            border: none;
            box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 12%);
            border-radius: 10px 0px 0 10px;

            td {
                border: none;
                padding: 5px 21px;
                color: #625e5e;
                font-size: 14px;

                &:first-child {
                    border-radius: 10px 0px 0 10px;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0;
                }

                b {
                    display: block;
                    color: #000;
                }
            }

            &.collapse-tr {
                background: transparent;
                box-shadow: none;

                td {
                    padding: 0;

                    .collapse-row {
                        display: flex;
                        margin-top: -10px;

                        .left-block {
                            flex: 1 1 320px;
                            background: #F1F6F8;
                            padding: 13px;
                        }

                        .right-block {
                            flex: 1 1 100%;
                            padding: 10px;
                            background: #ECF2F4;

                            ul {
                                list-style: none;
                                display: flex;
                                flex-wrap: wrap;
                                gap: 10px 0px;

                                li {
                                    width: calc(100% / 5);
                                }
                            }
                        }
                    }

                    .bg-list-tbl {
                        .date-list {
                            background: #16B3E4;
                            border-radius: 10px;
                            color: #fff;
                            list-style: none;
                            padding: 12px;
                            justify-content: center;

                            li {
                                margin-right: 10px;
                                padding-right: 10px;

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }
                            }
                        }

                        .reference-list {
                            background: #003A52;
                            color: #fff;
                            border-radius: 10px;
                            list-style: none;
                            overflow: hidden;

                            li {
                                padding: 12px;
                                width: calc(100% / 5) !important;
                                padding-top: 15px;

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }

                                &.phone-items {
                                    background: #004F6F;

                                    b {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 5px;
                                        gap: 0 5px;

                                        span {
                                            font-size: 14px;
                                            word-break: break-all;
                                        }

                                        i {
                                            font-size: 20px;
                                            padding-right: 5px;
                                        }
                                    }
                                }

                                .ms-Icon {
                                    color: white;
                                }
                            }
                        }

                        .reference-list-triage {
                            background: #003A52;
                            color: #fff;
                            border-radius: 10px;
                            list-style: none;
                            overflow: hidden;

                            li {
                                padding: 12px;
                                width: calc(100% / 3) !important;
                                padding-top: 15px;

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }

                                &.phone-items {
                                    background: #004F6F;

                                    b {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 5px;
                                        gap: 0 5px;

                                        span {
                                            font-size: 14px;
                                            word-break: break-all;
                                        }

                                        i {
                                            font-size: 20px;
                                            padding-right: 5px;
                                        }
                                    }
                                }

                                .ms-Icon {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dis_bl {
    background-color: white;

    .ms-Icon {
        color: gray;
    }
}

.sync_property {
    position: absolute;
    color: #ff1f1f;
    animation: rotation 2s infinite linear;

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }
}

.sync_content {
    margin-left: 25px;
    margin-top: 3px;
    color: #ff1f1f;
    animation: blinker 1s linear infinite;

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
}

.span_text_lt {
    margin: auto;
    color: #0181ae;
    font-size: 14px;
    font-weight: 500;
    text-align: end;
    width: 50%;
}

.span_text_rt {
    margin: auto;
    color: #0181ae;
    font-size: 14px;
    font-weight: 500;
    text-align: start;
    width: 50%;
}

//////////
.step-wrap {
    margin-top: 20px;

    .title-block {
        color: #848484;
        font-size: 12px;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 6px;
    }

    .step-list {
        border: 1px solid #EBEBEB;
        background: #FFF;
        padding: 20px;

        flex-wrap: wrap;
        display: flex;
        margin: 0 -10px 20px -10px;

        .step-item {
            padding: 0 10px;
            width: calc(100% / 3);

            .status-msg-block {
                display: flex;
                gap: 0 10px;

                .status-txt {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 19px;

                    &.error-txt {
                        color: #CF1B1B;
                        font-style: italic;
                        text-decoration-line: none;
                    }

                    &.approve-txt {
                        color: #3DB77C;
                    }

                    &.reject-txt {
                        color: red;
                    }
                    &.semi-txt{
                        color:#32de84;
                    }
                }
            }

            .step-status {
                display: flex;
                align-items: center;
                padding: 12px 20px;
                justify-content: space-between;
                margin-bottom: 12px;
                border: 0.5px solid transparent;
                transition: all 0.3s ease-in-out;

                h5 {
                    color: #0E0E0E;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 19px;
                    margin: 0;
                }

                .progress-wrap {
                    display: flex;
                    align-items: center;
                }

                .progress {
                    width: 144px;
                    height: 7px;
                    border-radius: 0;
                    margin-left: 15px;
                    margin-right: 10px;
                    flex: 1 1 100%;

                    .progress-bar {
                        width: 100%;
                    }
                }

                .img {
                    width: 18px;
                    height: 18px;
                    object-fit: cover;
                    flex: 1 1 18px;

                }

                &.step-success {
                    background: #F2FFF9;
                    cursor: pointer;

                    &:hover {
                        border: 0.5px solid #3DB77C;
                        box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.15);
                    }

                    .progress-bar {
                        background: #24B04E;
                    }
                }

                &.step-error {
                    background: #FFF2F2;

                    .progress-bar {
                        background: #AD3F3F;
                    }
                }

                &.step-pending {
                    background: #FFF9F2;

                    .progress-bar {
                        background: #FD8D14;
                    }

                }

                &.step-pending-screen {
                    background: #FFF9F2;

                    .progress-bar {
                        background: #f0dd6f;
                    }

                }

                &.step-disabled {
                    background: #e5e5e5;

                    .progress-bar {
                        background: #afafaf;
                    }

                    h5 {
                        color: #afafaf;
                    }
                }

                &.step-rejected {
                    cursor: pointer;
                    background: #d1fbc5;

                    &:hover {
                        border: 0.5px solid #AD3F3F;
                        box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.15);
                    }

                    .progress-bar {
                        background: #ff7474;
                    }
                }

                &.step-approved {
                    background: #d1fbc5;
                    cursor: pointer;

                    &:hover {
                        border: 0.5px solid #3DB77C;
                        box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.15);
                    }

                    .progress-bar {
                        background: #24B04E;
                    }
                }
                &.step-semi-approved {
                    background:#6dfdaf4a;
                    cursor: pointer;

                    &:hover {
                        border: 0.5px solid #3DB77C;
                        box-shadow: 4px 4px 16px 1px rgba(172, 166, 52, 0.15);
                    }

                    .progress-bar {
                        background: #32de84;
                    }
                }
            }
        }

    }

}

.step-card {
    border: 1px solid #EBEBEB;
    background: #FFF;
    padding: 20px;
    margin: 0 -10px;

    .step-header {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-text {
            color: #0E0E0E;
            font-size: 15px;
            font-weight: 600;
            line-height: 19px;
            margin-bottom: 0;
        }

        .default-button {
            color: #3F6BAD;
            font-size: 12px;
            font-weight: 700;
            line-height: 19px;
            border: 1px solid #3F6BAD;
            background: #FFF;
            padding: 5px;
        }

        .primary-view-button {
            color: #fff;
            border: none;
            font-size: 12px;
            font-weight: 700;
            line-height: 19px;
            padding: 5px;
            height: 32px;

            &:hover {
                background-color: #02a2bc;

            }
        }

        .accept-reject {
            display: flex;
            flex-wrap: wrap;
            gap: 0 10px;

        }
    }

    .step-header-cos {
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-text-cos {
            color: #0E0E0E;
            font-size: 14px;
            font-weight: 700;
            line-height: 19px;
            margin-bottom: 0;
        }

        .step-seven-cos {
            color: #00A9FF !important;
            font-size: 18px !important;
            font-weight: 650 !important;
            margin-top: 10px !important;
        }

        .cos-validate {
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 0;
            cursor: pointer;

            .pding {
                padding-left: 15px;
                padding-right: 5px;
            }

            .link {
                color: #746d6d;
            }

            .link:hover {
                color: rgb(71, 64, 64);
                cursor: pointer;
            }
        }

        .link-dsgn {
            margin-right: 30px;
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 0;
            cursor: pointer;
        }

        .link-dsgn-erview {
            margin-right: 20px;
            font-size: 13px;
            font-weight: bold;
            line-height: 19px;
            margin-bottom: 0;
            color: brown;
            cursor: pointer;
        }

        .title-text-cos-subtxt {
            color: #0E0E0E;
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 0;
        }

        .pos-rel {
            position: relative;
            z-index: 11;
            background-color: #fff;

        }

        .err-lnk-cos {
            .err-lnk-txt {
                display: block;
                text-align: center;
                background-color: #fff;
                position: absolute;
                font-size: 13px;
                inset: 0;
                bottom: unset;
                width: fit-content;
                transform: translate(0px, 25px);
                margin: auto;
                margin-top: 10px;
                padding: 0 8px;
                z-index: 9;
            }

            &::after {
                content: "";
                width: 108%;
                height: 30px;
                inset: 0;
                left: unset;
                display: block;
                position: absolute;
                transform: translate(12px, 14px);
                border: 1px solid red;
                padding: 5px;
                z-index: -1;
            }
        }

        .accept-reject {
            display: flex;
            flex-wrap: wrap;
            gap: 0 10px;

        }
    }

    .step-list {
        border: none;
        background: #FFF;
        padding: 0;
        flex-wrap: wrap;
        display: flex;

        .step-item {
            .step-tile-count {
                padding: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    box-shadow: 4px 4px 16px 1px rgba(0, 0, 0, 0.15);
                }

                &.step-red {
                    background: #D63B48;
                }

                &.step-green {
                    background: #2AC05E;
                }

                &.step-yellow {
                    background: #D77B38;
                }

                .title-blocks {
                    color: #FFF;
                    margin: 0;

                    h4 {
                        color: #FFF;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 19px;
                        display: flex;
                    }
                }

                .count-block {
                    width: 59px;
                    height: 59px;
                    background: rgba(255, 255, 255, 0.12);
                    color: #FFF;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .step-tile-disable-count {
                padding: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.step-red {
                    background: #D63B48;
                }

                &.step-green {
                    background: #2AC05E;
                }

                &.step-yellow {
                    background: #D77B38;
                }

                .title-blocks {
                    color: #FFF;
                    margin: 0;

                    h4 {
                        color: #FFF;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 19px;
                        display: flex;

                    }
                }

                .count-block {
                    width: 59px;
                    height: 59px;
                    background: rgba(255, 255, 255, 0.12);
                    color: #FFF;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }


        }
    }

    .step-body {
        border-top: 1px solid #EBEBEB;
        margin-top: 10px;
        padding-top: 10px;

        table {
            width: 100%;
            table-layout: fixed;
            border-collapse: separate;
            border: none;
            border-spacing: 0 5px;

            thead {
                tr {
                    border: 1px solid #EBEBEB;
                    background: #D9E8FF;

                    th {
                        color: #0E213E;
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 19px;
                        padding: 12px 20px;
                        border: none;
                    }
                }

            }

            tbody {
                tr {
                    td {
                        padding: 12px 20px;
                        background: #F4F6F8;
                        color: #000;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 19px;
                        border: none;

                        &.is-yes {
                            color: #3F6BAD;
                            text-decoration-line: underline;
                        }
                    }
                }

            }
        }

    }

    .step-footer {
        padding-top: 10px;
        border-top: 1px solid #EBEBEB;
        margin-top: 20px;

        .action-block {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .step-button {
                color: #0E0E0E;
                font-size: 12px;
                font-weight: 600;
                line-height: 19px;
                margin-right: 12px;
            }

            .primary-button {
                color: #fff;
                border: none;
                font-size: 12px;
                font-weight: 700;
                line-height: 19px;
                padding: 5px;
                height: 32px;

                &:hover {
                    background-color: #02a2bc;

                }
            }
        }

        .step-comment {
            h4 {
                color: #848484;
                font-size: 12px;
                font-weight: 600;
                line-height: 19px;
                margin-bottom: 0;
            }

            span {
                color: #0E0E0E;
                font-size: 12px;
                font-weight: 400;
                line-height: 19px;
                margin-top: 3px;
            }
        }

    }

    .step-comment {
        h5 {
            color: #0E0E0E;
            font-size: 12px;
            font-weight: 700;
            line-height: 19px;
            margin: 0;
        }

        .reject-reason {
            font-weight: 400;
            font-size: 13px;
            margin: 0;
        }

        .approve-reason {
            color: #24B04E;
            font-size: 13px;
            font-weight: 400;
            margin: 0;
        }
    }
}

.high-light {
    background-color: #ffb8b89c;
}

.th-heading {
    text-align: center;
    background-color: aliceblue;
    padding: 15px;
    top: -1px;
    position: sticky;
    z-index: 999;
}

.table-installation {
    overflow-y: auto;
    height: 550px;
}

@media screen and (max-width: 1366px),
(max-height: 768px) {
    .table-installation {
        height: 515px;
    }
}

.paddingCmnSmdcc-wrap {
    width: 100%;
    margin: auto;

    .paddingCmnsmdcc-tbls {
        table-layout: fixed;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 1em;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        border: none;


        tr {
            background: #fff;
            border: none;
            box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 12%);


            td {
                border: none;
                padding: 5px 20px;
                color: #625e5e;
                font-size: 14px;

                &:first-child {
                    border-radius: 10px 0px 0 10px;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0;
                }

                b {
                    display: block;
                    color: #000;
                }
            }

            &.collapse-tr {
                background: transparent;
                box-shadow: none;

                td {
                    padding: 0;

                    .collapse-row {
                        display: flex;
                        margin-top: -10px;

                        .left-block {
                            flex: 1 1 350px;
                            background: #ECF2F4;
                            padding: 10px;
                            padding-left: 45px;
                        }

                        .right-block {
                            flex: 1 1 100%;
                            padding: 10px;
                            background: #ECF2F4;

                            ul {
                                list-style: none;
                                display: flex;
                                flex-wrap: wrap;
                                gap: 15px 0px;

                                li {
                                    width: calc(100% / 3);
                                }
                            }
                        }
                    }


                }
            }
        }
    }
}

.dashboard-outer-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
    margin-top: 1.5rem;
    margin-left: -4px;

}

.dashboard-grid-container {
    columns: 3 300px;
    column-gap: 1rem;
    width: 100%;
    margin: 4px auto 0 auto;

    div {
        margin: 0 0.5rem 0.5rem 0;
        display: inline-block;
        width: 100%;
        padding: 5px;
        transition: all .25s ease-in-out;
    }
}

section {
    width: 100%;
    transition: all .25s ease-in-out;
    padding-top: 10px;
}

.multi_date {
    margin-top: 15px;
    margin-bottom: -5px;
    margin-left: 55%;
    width: 210px;
    height: 38px;
    font-size: 13px;
    font-weight: 400;
    font-family: $font1;
    border: 1px solid #b3aeae;
    border-radius: 2px;
    text-align: center;
    justify-content: center;
}

.radioLabel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* Adjust the space between radio buttons as needed */
}

.notfound {
    margin-top: 2%;
    background: url("../assets/not_foundbackground.png") no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 400px;
    opacity: 0.3;
}

.notfound_img {
    margin-top: 2%;
    background: url("../assets/dashboard_center.webp") no-repeat;
    background-position: center;
    height: 400px;
}

.containersortmultidraginput {
    display: flex;
}

.containersortmultidraginput>div {
    border: 1px solid #0000007d;
}

.containersortmultidraginput>div:nth-child(1)>div span.arrow-drag-drop {
    background: url("../assets/right_arrow.png") no-repeat center right;
    background-size: 8px 12px;
    padding-right: 12px
}

.containersortmultidraginput>div:nth-child(2)>div span.arrow-drag-drop {
    background: url("../assets/left_arrow.png") no-repeat center left;
    background-size: 8px 12px;
    padding-left: 12px
}

.multidragcol {
    padding-left: 0px !important;
    padding-right: 0px !important;
    border: 1px solid #000000;
}

.multidraglabel {
    padding-left: 0px !important;
    padding-right: 0px !important;
    text-align: center !important;

}

.download-button {
    border: none;
    background: none;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
    color: #007bff;
}

.download-button:hover {
    text-decoration: underline;
    color: #0056b3;
}

.mui-date-time-picker {
    position: relative;

    .MuiInputBase-input {
        font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 400;
        box-shadow: none;
        margin: 0px;
        box-sizing: border-box;
        background: none transparent;
        color: #333333;
        width: 100%;
        border-radius: 0px !important;
    }

    .MuiFormControl-root {
        overflow: hidden;
        min-width: 0px !important;
        background-color: white
    }

    .ms-Button {
        pointer-events: auto;
        background: transparent;
        position: absolute;
        right: -30px;
        top: 10px;
    }

    fieldset.MuiOutlinedInput-notchedOutline {
        border-color: rgb(133 133 133);
    }

}


.ql-container.ql-snow {
    max-height: 300px;
    overflow-y: auto;
}

.ql-editor {
    min-height: 120px;
}

.text-wrap-reason {
    text-wrap: wrap;
    text-align: center;
}

.paddingStep2Cmn-wrap {
    width: 100%;
    margin: auto;

    .paddingstep2Cmn-tbls {
        table-layout: absolute;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 1em;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        border: none;


        tr {
            background: #fff;
            border: none;
            box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 12%);


            td {
                border: none;
                padding: 5px 20px;
                color: #625e5e;
                font-size: 14px;

                &:first-child {
                    border-radius: 10px 0px 0 10px;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0;
                }

                b {
                    display: block;
                    color: #000;
                }
            }

            &.collapse-tr {
                background: transparent;
                box-shadow: none;

                td {
                    padding: 0;

                    .collapse-row {
                        display: flex;
                        margin-top: -10px;

                        .left-block {
                            flex: 1 1 350px;
                            background: #F1F6F8;
                            padding: 10px;
                        }

                        .right-block {
                            flex: 1 1 100%;
                            padding: 10px;
                            background: #ECF2F4;

                            ul {
                                list-style: none;
                                display: flex;
                                flex-wrap: wrap;
                                gap: 5px 0px;

                                li {
                                    width: calc(100% / 8);
                                }
                            }
                        }
                    }

                    .bg-list-tbl {
                        .date-list {
                            background: #16B3E4;
                            border-radius: 10px;
                            color: #fff;
                            display: flex;
                            list-style: none;
                            padding: 12px;
                            justify-content: center;

                            li {
                                margin-right: 10px;
                                padding-right: 10px;

                                &:first-child {
                                    border-right: 1px solid #fff;
                                }

                                &:last-child {
                                    margin: 0;
                                    padding: 0;
                                }

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }
                            }
                        }

                        .reference-list {
                            background: #003A52;
                            color: #fff;
                            border-radius: 10px;
                            list-style: none;
                            overflow: hidden;

                            li {
                                padding: 12px;
                                width: calc(100% / 4) !important;
                                padding-top: 15px;

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }

                                &.phone-items {
                                    background: #004F6F;

                                    b {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 5px;
                                        gap: 0 5px;

                                        span {
                                            font-size: 14px;
                                            word-break: break-all;
                                        }

                                        i {
                                            font-size: 20px;
                                            padding-right: 5px;
                                        }
                                    }
                                }

                                .ms-Icon {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .paddingdataerror-tbls {
        table-layout: fixed;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 1em;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        border: none;


        tr {
            background: #fff;
            border: none;
            box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 12%);


            td {
                border: none;
                padding: 5px 20px;
                color: #625e5e;
                font-size: 14px;

                &:first-child {
                    border-radius: 10px 0px 0 10px;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0;
                }

                b {
                    display: block;
                    color: #000;
                }
            }

            &.collapse-tr {
                background: transparent;
                box-shadow: none;

                td {
                    padding: 0;

                    .collapse-row {
                        display: flex;
                        margin-top: -10px;

                        .left-block {
                            flex: 1 1 350px;
                            background: #F1F6F8;
                            padding: 10px;
                        }

                        .right-block {
                            flex: 1 1 100%;
                            padding: 10px;
                            background: #ECF2F4;

                            ul {
                                list-style: none;
                                display: flex;
                                flex-wrap: wrap;
                                gap: 5px 0px;

                                li {
                                    width: calc(100% / 8);
                                }
                            }
                        }
                    }

                    .bg-list-tbl {
                        .date-list {
                            background: #16B3E4;
                            border-radius: 10px;
                            color: #fff;
                            display: flex;
                            list-style: none;
                            padding: 12px;
                            justify-content: center;

                            li {
                                margin-right: 10px;
                                padding-right: 10px;

                                &:first-child {
                                    border-right: 1px solid #fff;
                                }

                                &:last-child {
                                    margin: 0;
                                    padding: 0;
                                }

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }
                            }
                        }

                        .reference-list {
                            background: #003A52;
                            color: #fff;
                            border-radius: 10px;
                            list-style: none;
                            overflow: hidden;

                            li {
                                padding: 12px;
                                width: calc(100% / 4) !important;
                                padding-top: 15px;

                                span {
                                    color: #fff;
                                }

                                b {
                                    color: #fff;
                                }

                                &.phone-items {
                                    background: #004F6F;

                                    b {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 5px;
                                        gap: 0 5px;

                                        span {
                                            font-size: 14px;
                                            word-break: break-all;
                                        }

                                        i {
                                            font-size: 20px;
                                            padding-right: 5px;
                                        }
                                    }
                                }

                                .ms-Icon {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.calendarsInline .goToday {
    margin-left: 76%;
}

.ql-tooltip.ql-editing {
    left: 0;
    margin-left: 14%;
    top: 28px;
}

.shared-text {
    margin-right: 10px;
}

.cos_details-remove>span>i.ms-Icon {
    font-size: 18px;
    padding: 0;
    color: red !important;
}

.cos_details-remove>span>i.ms-Icon:hover {
    color: red !important;
}

.cos-asset-history {
    margin-top: 10px;
    color: #0181ae;

    font-size: 20px;
    font-weight: 700;
}

.add-new-btn {
    margin-top: 15px;
    position: relative;
    display: flex;
}

.cos-history-tbl {
    position: relative;
    margin-top: 8px;
}

.paddingCmnCos-tbls {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: none;


    tr {
        background: #fff;
        border: none;
        box-shadow: 0px 2px 15px 0px rgb(0 0 0 / 12%);
        border-radius: 10px 0px 0 10px;

        td {
            border: none;
            padding: 5px 8px;
            color: #625e5e;
            font-size: 14px;

            &:first-child {
                border-radius: 10px 0px 0 10px;
            }

            &:last-child {
                border-radius: 0 10px 10px 0;
            }

            b {
                display: block;
                color: #000;
            }
        }

        &.collapse-tr {
            background: transparent;
            box-shadow: none;

            td {
                padding: 0;

                .collapse-row {
                    display: flex;
                    margin-top: -10px;

                    .left-block {
                        flex: 1 1 320px;
                        background: #F1F6F8;
                        padding: 13px;
                    }

                    .right-block {
                        flex: 1 1 100%;
                        padding: 10px;
                        background: #ECF2F4;

                        ul {
                            list-style: none;
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px 0px;

                            li {
                                width: calc(100% / 5);
                            }
                        }
                    }
                }

                .bg-list-tbl {
                    .date-list {
                        background: #16B3E4;
                        border-radius: 10px;
                        color: #fff;
                        list-style: none;
                        padding: 12px;
                        justify-content: center;

                        li {
                            margin-right: 10px;
                            padding-right: 10px;

                            span {
                                color: #fff;
                            }

                            b {
                                color: #fff;
                            }
                        }
                    }

                    .reference-list {
                        background: #003A52;
                        color: #fff;
                        border-radius: 10px;
                        list-style: none;
                        overflow: hidden;

                        li {
                            padding: 12px;
                            width: calc(100% / 5) !important;
                            padding-top: 15px;

                            span {
                                color: #fff;
                            }

                            b {
                                color: #fff;
                            }

                            &.phone-items {
                                background: #004F6F;

                                b {
                                    display: flex;
                                    align-items: center;
                                    margin-top: 5px;
                                    gap: 0 5px;

                                    span {
                                        font-size: 14px;
                                        word-break: break-all;
                                    }

                                    i {
                                        font-size: 20px;
                                        padding-right: 5px;
                                    }
                                }
                            }

                            .ms-Icon {
                                color: white;
                            }
                        }
                    }

                    .reference-list-triage {
                        background: #003A52;
                        color: #fff;
                        border-radius: 10px;
                        list-style: none;
                        overflow: hidden;

                        li {
                            padding: 12px;
                            width: calc(100% / 3) !important;
                            padding-top: 15px;

                            span {
                                color: #fff;
                            }

                            b {
                                color: #fff;
                            }

                            &.phone-items {
                                background: #004F6F;

                                b {
                                    display: flex;
                                    align-items: center;
                                    margin-top: 5px;
                                    gap: 0 5px;

                                    span {
                                        font-size: 14px;
                                        word-break: break-all;
                                    }

                                    i {
                                        font-size: 20px;
                                        padding-right: 5px;
                                    }
                                }
                            }

                            .ms-Icon {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cos-remove>span>i.ms-Icon {
    font-size: 18px;
    color: red !important;
}

.cos-remove>span> :hover {
    font-size: 18px;
    color: red !important;
}

.cos-view>span>i.ms-Icon {
    font-size: 24px;
}

.err-spn {
    font-size: 13px;
    font-weight: 500;
    color: grey;
    padding-right: 10px;
}

.no-err-spn {
    font-size: 13px;
    font-weight: 500;
    color: #8a7da5;
    padding-right: 10px;
}

.no-err-spn-cos {
    font-size: 13px;
    font-weight: 500;
    color: #ad3f3f;
    padding-right: 10px;
}

.cos-history>span>i.ms-Icon {
    color: red !important;
}

// cos-enhancement
.setp-wraps {
    margin: 5px;
    margin-bottom: 45px;
    padding: 5px;
    background: #f2f9fb;
    border: 1px solid #a5a4a482;
    border-radius: 5px;

    .step-titles {
        padding: 3px 20px;
        width: 90px;
        overflow: hidden;
        margin-left: 10px;
        margin-top: -19px;
        color: #fff;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 6px;

        &::after {
            content: '';
            position: absolute;
            width: 80px;
            height: 50px;
            inset: 0;
            left: unset;
            top: 0px;
            margin: 0;
            border-radius: 100px;
            transform: translate(22px, 0px);
        }

    }

    .success-approve {
        background-color: #22B14C;
    }

    .cos-err {
        background-color: #ad3f3f;
    }

    .cos-reject {
        background-color: #ff7474;
    }

    .cos-inprogress {
        background-color: #ff9300;
    }

    .cos-currentstep {
        background-color: rgb(71, 196, 238);
    }

    .cos-dissable-step {
        background-color: #7f7f7f;
    }

    .cos-enable-step {
        background-color: #272727;
    }

    .right-blocker {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        align-items: center;

        .progress-wrap {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: flex-end;

            .progress {
                width: 200px;
                height: 7px;
                border-radius: 0;
                margin-left: 15px;
                margin-right: 10px;
            }

            .progress-bar {
                width: 100%;
            }

            .approve-txt {
                color: #093c4e;
                font-size: 12px;
                font-weight: 600;
                line-height: 19px;
                white-space: nowrap;
            }

            .success-approve-txt {
                color: #22B14C;
            }

            .cos-err-txt {
                color: #ad3f3f;
            }

            .cos-reject-txt {
                color: #ff7474;
            }

            .cos-inprogress-txt {
                color: #ff9300;
            }
        }

        .progress-wrap-stp1 {
            display: flex;
            align-items: center;

            .progress {
                width: 200px;
                height: 7px;
                border-radius: 0;
                margin-left: 15px;
                margin-right: 10px;
            }

            .progress-bar {
                width: 100%;
            }

            .approve-txt {
                color: #093c4e;
                font-size: 12px;
                font-weight: 600;
                line-height: 19px;
                white-space: nowrap;
            }

            .success-approve-txt {
                color: #22B14C;
            }

            .cos-err-txt {
                color: #ad3f3f;
            }

            .cos-reject-txt {
                color: #ff7474;
            }

            .cos-inprogress-txt {
                color: #ff9300;
            }
        }

        .prograss-button {
            .cmnBtn-outline {
                font-size: 14px;
                color: #000000;
                border: 1px solid #000000;
                font-weight: 400;
                border-radius: 2px;
                height: 38px;
                white-space: nowrap;
                margin-top: 0px !important;
            }
        }

        .enable_view_det_link {
            margin-right: 20px;
            font-size: 13px;
            font-weight: bold;
            line-height: 19px;
            margin-bottom: 0;
            color: brown;
            cursor: pointer;
        }

        .disable_view_det_link {
            margin-right: 20px;
            font-size: 13px;
            font-weight: bold;
            line-height: 19px;
            margin-bottom: 0;
            pointer-events: none;
            color: #b38585;
            text-decoration: none;
        }

        .fs {
            font-size: 13px;
        }
        .icon-img{
            height: 20px;
            align-self: center;
        }

    }

    .step-lists {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 5px;
        align-items: center;
        position: relative;
        z-index: 11;
    }

    .step-blocks {
        width: calc(100% / 2);
        padding: 0 15px;

        .cos-validate {
            display: flex;
            gap: 10px;
            margin-top: 2px;
            color: #746d6d;
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 0;
            margin-left: -5px;
            white-space: nowrap;

            .enable_val_link {
                color: black;
                border: none;
                background: none;
                font-weight: 600;
            }

            .enable_val_link:hover {
                color: #746d6d;
                cursor: pointer;
            }

            .disable_val_link {
                color: grey;
                pointer-events: none;
                border: none;
                background: none;
            }
        }

        .acpt-btn {
            background-color: #38E54D;
            border-color: #38E54D;
            font-size: 13px;
        }

        .enable_success_link {
            font-size: 13px;
            font-weight: 600;
            line-height: 19px;
            margin-bottom: 0;
            border: none;
            background: none;
            cursor: pointer;
            color: #47c4ee;
        }

        .enable_success_link:hover {
            color: #1335acbf;
            cursor: pointer;
        }

        .disable_success_link {
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 0;
            color: #1893f49e;
            pointer-events: none;
            border: none;
            background: none;
        }


    }

    .step-blocks-stp1 {
        width: calc(100% / 3);
        padding: 0 15px;

        .cos-validate {
            display: flex;
            gap: 10px;
            margin-top: 2px;
            color: #746d6d;
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 0;
            margin-left: -5px;
            white-space: nowrap;

            .enable_val_link {
                color: black;
                font-weight: 600;
                border: none;
                background: none;
            }

            .enable_val_link:hover {
                color: #746d6d;
                cursor: pointer;
            }

            .disable_val_link {
                color: grey;
                pointer-events: none;
                border: none;
                background: none;
            }
        }

        .acpt-btn {
            background-color: #38E54D;
            border-color: #38E54D;
            font-size: 13px;
        }

        .enable_success_link {
            font-size: 13px;
            font-weight: 600;
            line-height: 19px;
            margin-bottom: 0;
            cursor: pointer;
            border: none;
            background: none;
            color: #47c4ee;
        }

        .enable_success_link:hover {
            color: #1335acbf;
            cursor: pointer;
        }

        .disable_success_link {
            font-size: 13px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 0;
            color: #1893f49e;
            pointer-events: none;
            border: none;
            background: none;
        }
   
    }

    .title {
        color: #0E0E0E;
        font-size: 12px;
        font-weight: 700;
        line-height: 19px;
        margin: 0;
    }
}

@keyframes loading {

    0%,
    100% {
        content: "Loading .";
    }

    33% {
        content: "Loading ..";
    }

    66% {
        content: "Loading ...";
    }
}

.loading-text::after {
    display: inline-block;
    animation: loading 1s infinite;
    content: "Loading .";
}

.loading-text {
    text-align: center;
    font-style: italic;
    font-size: 14px;
    color: rgb(224, 153, 93);
    margin-left: 2%;
    font-weight: 700;
}

.cos-edit-dsbl {
    .ms-Icon {
        pointer-events: none;
        opacity: 0.5;
    }
}

.ed-cos-btn {
    .ms-Button {
        background-color: rgb(249 250 251);
    }
}

.pallet-btn {
    float: right;
    margin-top: -40px;
}

.cos-refresh-button {
    background-color: #007458;
    color: #fff;
    border: none;
    font-size: small;
    height: 30px;
    width: 85px;
    transition: background-color 1.2s ease, color 1.3s ease;
}

.cos-refresh-button:hover {
    background-color: #099c7a;
    color: white;
}

@keyframes clickAnimation {
    0% {
        background-color: #3ecfad;
    }

    /* Start with default color */
    20% {
        background-color: #2feec1;
    }

    /* Color change at 20% */
    40% {
        background-color: #1cf7c4;
    }

    /* Color change at 40% */
    60% {
        background-color: #09ddab;
    }

    /* Color change at 60% */
    80% {
        background-color: #099c7a;
    }

    /* Color change at 80% */
    100% {
        background-color: #099c7a;
    }

    /* Return to default color */
}

/* Apply animation when button is actively clicked */
.cos-refresh-button:active {
    animation: clickAnimation 2s;
    /* Longer duration */
}

.cos-refresh-button:hover .ms-Button-icon {
    color: white !important;
}

.cos-err-btn {
    margin-top: 0px;
    height: 35px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #a72323 !important;
    border: none;

    &:hover {
        border: none;
        background-color: #c92323 !important;
    }
}

.cos-btn-view {
    margin-top: 0px;
    height: 35px;
    padding-left: 30px;
    padding-right: 30px;
}

//uninstallation revamp style
.enable-desc {
    display: flex;
    align-items: center;
    width: 70%;
}

.unins-disableStyle {
    opacity: 0.5;
    pointer-events: none;
}

.unins-boxShadowStyle {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 10px;
    background-color: #f9fafb !important;
}

.unins-nextStepsdisableStyle {
    opacity: 0.4;
    pointer-events: none;
    background-color: rgb(232, 238, 240) !important;
    margin-bottom: 25px;
    color: black;
}

.paddingCmnRel {
    margin-top: 40px;
}

.template-styl {
    position: absolute;

    .html-btn {
        border: 1px solid grey;
        border-bottom: none;
        border-radius: 0px;
        background-color: grey;
        color: white;
        height: 30px;
    }

}

.paste-btn {
    float: right;
    background-color: white;
    height: 35px;

    .ms-Icon {
        font-weight: 700;
        font-size: large;
        color: #ff0000 !important;
    }
}

.template-styl-previw {
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .preview-style {
        background-color: #003A52;
        color: white;
        cursor: default !important;
    }
}

.text-area-styl {
    width: 100%;
    height: 350px;
    padding: 25px;
    margin-top: 31px;
    outline: none !important;
    border: 1px solid black !important;
    background-color: #222831;
    color: white;

}

.text-area-styl::placeholder {
    color: white !important;
    font-size: 16px;
}

.textarea-wrapper {
    background-color: grey;
    padding: 4px;
}

.guid-popup {
    padding: 30px;
    font-size: 15px;
    font-family: sans-serif;
    background-color: #b3b4b642;

    p {
        margin-left: 30px;
        margin-top: 10px;
    }
}

.sample-template-styl {
    .sampl-html-btn {
        border: 1px solid grey;
        border-bottom: none;
        border-radius: 0px;
        background-color: grey;
        color: white;
        height: 30px;
    }

}

.terminal {
    background-color: grey;
    padding: 4px;
    margin-top: -35px;

    .ace_editor {
        height: 225px !important;
    }
}

.ms-Label-optimized {
    font-weight: 600 !important;
    font-size: 14px !important;
    margin-left:2px;
}

.ms-Label-optimized+.ms-Label-optimized {
    margin-left: 8%;
}


.note {
    color: red;
    font-size: 12px;
    font-weight: 500;
}

.hidden-private-info {
    margin-top: 5px;
    color: blue;
    font-size: 14px;
    font-weight: 500;

}

.asnsortmultidraginput {
    display: flex;
}


.asnsortmultidraginput > div:nth-child(1) > div span.arrow-drag-drop {
    width: 16px; 
    height: 25px; 
    background: url("../assets/arrow-right-s-fill.svg") no-repeat center right;
    padding-right: 23px; 
}


.asnsortmultidraginput>div:nth-child(2)>div span.arrow-drag-drop {
    width: 16px; 
    height: 25px; 
    background: url("../assets/arrow-left-s-fill.svg") no-repeat center left;
    padding-left: 25px;
    
}

.inputBox-optimized {
    margin-top: 20px;
    width: 76%;
    margin-bottom: 30px;


    .title-block {
        display: flex;
    }

    label.ms-Label.ms-Label-optimized.multidraglabel {
        max-width: 50% !important;
        flex: 1 1 50% !important;
        margin: 0 !important;
        &:last-child{
            border: none;
        }
        
        span {
            background: #0181aed9;
            margin: 0;
            padding: 15px 0 15px 67px;
            text-align: left;
            color: #fff;
            font-size: 16px;
            width: 100%;
            display: block;
        }
        
    }

    .asnsortmultidraginput {
        width: 100% !important;
        box-shadow: none !important;
    }


    .inputBox-optimized .asnsortmultidraginput>div div {
        box-shadow: none !important;
        margin: 0 -15px !important;
    }
}

.form-title {
    font-size: 20px;
    color: #004792;
    font-weight: 600;
}

.inputBox-optimized .asnsortmultidraginput>div {
    min-width: 50% !important;
    padding: 20px 15px !important;
    background: #55caf34a !important;
    border-color: transparent !important;
    border: 1px solid #0000007d;
}
.reset_btn {
    background: white;
    color: #D2122E;
    border-color: #D2122E;
    font-weight: 400;
    border-radius: 2px;
    height: 38px;
    margin-top: 25px;
    cursor: pointer;
}

.reset_btn:hover {
    background: #E32636; 
    color: white; 
}
.select-deselect-box {
    display: flex;
    justify-content: flex-end;
    margin-top: -16px;
    font-size: 14px;
    margin-right: 18px;
}

.select-deselect-box button {
    color: #0660f1;
    text-decoration: underline;
    border: none;
    background: transparent;
    font-weight: 600;
}

.select-deselect-box button:hover {
    color:#0660f1c4;
}
.scroll_inside_box{
    overflow-y:auto;
    max-height: 375px;
    margin-top: 8px;
    &::-webkit-scrollbar {
        width: 10px;
        margin-top: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #0181ae96;
    }

    &::-webkit-scrollbar-thumb {
        background: #E5E4E2 ;
        border-radius: 15px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #E5E4E2 ;
    }
}

.scrap-disabled-completed-button {
    background-color: rgba(127, 127, 127, 0.61);
    border: none;
    color: white;
    pointer-events: none;
  }
  
  .scrap-disabled-reject-button {
    background-color: rgb(255 152 162) !important;
    color: white;
    border: none;
    pointer-events: none;
  }
  
  .scrap-disabled-accept-button {
    background-color: rgba(61, 203, 78, 0.75);
    color: white;
    border: none;
    pointer-events: none;
  }
  
  .scrap-enabled-accept-button {
    background-color: #38E54D !important;
    border-color: #38E54D !important;
    font-size: 13px;
  }
  
.scrap-sync-loader {
    margin-top: -2px;
    margin-left: 4%;
    font-weight: 700;
  }
  
  .icon-transition {
    transition: transform 0.2s ease-in-out;
    z-index: 0;
    .ms-Button{
        background: transparent !important;
    }
  }
  
  .icon-transition.chevron-up {
    transform: rotate(180deg);
  }
  .scrap-auto-sug-list {
    width: 100% !important;
    z-index: 10 !important;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.currentScrapDetailsSearch {
    position: relative;

    .menu {
        position: absolute;
        box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px, rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;

    }

    .scrap-auto-sug-list.col {
        padding: 5px 10px;
        border: 1px solid black;
    }
}

  .scrapinputSuggestion {
    width: 450px !important;
    border: 1px solid #000000;
    border-radius: 2px;
    height: 40px;
    padding: 6px;
}

.scrapinputSuggestion:focus {
    outline: 1px solid #0181ae !important;
    border-color: #0181ae;
}
.scrap-suggestion-list{
    position:absolute,;
    background-color:white;
    max-height:200px;
    overflow-y: auto;
        &::-webkit-scrollbar {
            width: 12px;
            margin-top: 5px;
        }
    
        &::-webkit-scrollbar-track {
            background: rgb(246, 243, 247);
        }
    
        &::-webkit-scrollbar-thumb {
            background: #E5E4E2 ;
            border-radius: 12px;
        }
    
        &::-webkit-scrollbar-thumb:hover {
            background: #E5E4E2 ;
        }
}
  .ribbon {
    width: 63px;
    font-size: 12px;
    border-radius: 0px;
    color: white;
    --f: 8px; /* control the folded part*/
    --r: 5px; /* control the ribbon shape */
    --t: 5px; /* the top offset */
    font-weight: 500;
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  }
  
.open-tr {
    display:contents;
}
.close-tr {
    display:none;
}
.view-more-link {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    margin: 10px 0;
    font-size: 16px;
    color: #007bff;
    text-decoration: none !important;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
    border-radius: 4px;
    transition: filter 0.3s ease;
  }
  
  .view-more-link:hover {
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.2));
  }
  
  .view-more-link:focus {
    outline: none;
    filter: drop-shadow(0 0 0 3px rgba(0, 123, 255, 0.5));
  }
.delte-history.ms-Dialog-main{
        min-width: 400px !important;
}
.dialog-content{
    color: #C40C0C;
    text-align: left;
    font-size: small;
}

.location-item:hover {
    background-color:rgb(193 193 193 / 36%);
}
.single-rec_validate {
    display: flex;
    gap: 10px;
    margin-top: 2px;
    color: #746d6d;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 0;
    margin-left: -10px;
    white-space: nowrap;
    .link {
        font-weight: 600;
        border: none;
        background: none;
        cursor: pointer;
    }
    
    .accept_link {
        color: #03a917;
    }
    
    .accept_link:hover {
        color: #03a917c9; 
    }
    
    .reject_link {
        color: red;
    }
    
    .reject_link:hover {
        color: #FF6B6B; 
    }
    .pending_link {
        color: #ff8c00;
        background-color: #ffefcb;
        border-radius: 5px;
    }
    
    .pending_link:hover {
        color: #ff8c00ba; 
    }
}
.single-rec-aprove-reject-link{
    border: none;
    background: none;
    font-weight: 600;
    font-size: 14px;
}
.custom-icon-accept-reject {
    font-size: 20px; 
    font-weight: 900;
}
.custom-toggle .ms-Toggle-stateText {
    color: #0181ae !important;
    font-weight: 600 !important;
}
.export_document_style {
    border: none;
    color: #0181AE !important;
    background-color:transparent !important;
    padding: 0
}
.action-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 5px;
}

//------------------- triage css
.action-btn {
    height: 35px !important;
    background-color: #0181ae;
    font-size: 16px;
  }
  
  .action-btn:hover {
    background-color: #319abf !important;
    border: none;
  }
  
  .action-btn .ms-Icon {
    color: white !important;
    margin-top: 5px;
  }
  
  .menu-item {
    padding: 8px 16px;
    margin: 0;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    color: inherit; /* Ensure text color is inherited if not set directly */
  }
  
  .menu-item:hover {
    background-color: #f0f0f0;
  }
  .menu-link {
    border: none;
    background: transparent;
    text-decoration: none; /* No underline by default */
    text-align: start;
  }
  
  .menu-link:hover {
    text-decoration: underline; /* Underline on hover */
  }
  /* Specific colors for different menu items */
  .menu-item.accepted .menu-link {
    color: darkgreen; /* Green color for key 1 */
  }
  
  .menu-item.rejected .menu-link {
    color: darkred; /* Red color for key 2 */
  }
  
  .menu-item.pending .menu-link {
    color: #3795BD; /* Blue color for key 3 */
  }
  .menu-item.disabled {
    pointer-events: none;  /* for the records in triage having count 0*/
  }
  .menu-item.disabled .menu-link {
    color: grey !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
  .disabled-link {
    pointer-events: none;
    cursor: not-allowed;
  }
  .menu-item .menu-link {
    border: none;
    outline: none;
    background-color: transparent;
  }

  .menu-item .menu-link:hover {
    text-decoration: underline;
  }
  
.step-comment1{
    display: flex !important;
    h5 {
        color: #0E0E0E;
        font-size: 12px;
        font-weight: 700;
        line-height: 19px;
        margin: 0;
    }

    .reject-reason {
        font-weight: 450;
        font-size: 13px;
        margin-left: 3px;
    }
}
.mg-tp{
    margin-top: -25px !important;
}
/* Apply styles to visually indicate disabled state */
.disabled-pagination {
    pointer-events: none; 
    opacity: 0.5; /* Make it look disabled */
}

.disabled-page {
    cursor: not-allowed; /* Change cursor to indicate disabled state */
    opacity: 0.5; /* Make it look disabled */
}

/* Disable pagination item styling */
.item.disabled {
    cursor: not-allowed;
    color: gray; /* Change text color to indicate it's disabled */
    pointer-events: none; /* Disable pointer events */
    opacity: 0.5; /* Make it look disabled */
}

.disabled-report-page {
    pointer-events: none;
    opacity: 0.5;
}
.mgn-30{
    margin-left: -30px;
}
.instaHistoryText{
    background-color: transparent !important;
    .ms-Button-flexContainer .ms-Icon{
        color: grey !important;
    }
}
.instaDeleteIcon{
    .ms-Button-flexContainer .ms-Icon{
        color: red !important;
    }
    .ms-Button-flexContainer .ms-Icon:hover{
        color: red !important;
    }
}

.spinnerInstallation{
    margin-top: 8px;
    margin-left: -135px;
}
.chevInsta{
    display: flex;
    margin-top: -31px;
    margin-left: 95px;
    .ms-Button{
        margin-top: -25px;
        background: transparent;
    }
}
.process-fail-Btn {
    font-size: 14px;
    color: #ffffff;
    background-color: #d30c2a;
    border: 1px solid #d30c2a;
    font-weight: 400;
    margin-top: 0px;
    height: 35px;
    padding-left: 30px;
    padding-right: 30px;

    &:hover {
        background-color: #d30c2ac7;
        border: 1px solid #d30c2ac7;
        color: #ffffff;
    }

    &:disabled {
        background-color: #bebbb8;
        border: 1px solid #bebbb8;
    }
}
.tri-action-btn-dsbl{
    background-color: #9bb1b9d9;
    color: white;
    border: none;
}
.report-mod-remove>span>i.ms-Icon {
    font-size: 18px;
    color: grey !important;
    background-color: transparent !important;
}

.report-mod-remove>span> :hover {
    font-size: 18px;
    color: grey !important;
}
.report-mod-view>span>i.ms-Icon {
    font-size: 24px;
    color:grey !important;
    background-color: transparent !important;
}

.reject-file-button {
    background-color: #C7253E;
    color: #fff;
    border: none;
    font-size: small;
    height: 30px;
    margin-right: 10px;
    transition: background-color 1.2s ease, color 1.3s ease;
}
.reject-file-button:hover {
    background-color: #F95454;
    color: white;
}
.reject-file-button:active {
    animation:  2s;
    /* Longer duration */
}
.data-box {
    padding: 6px 0 12px 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-bottom: 15px;
    margin-top: 6px;
    border-radius: 4px;
}

.data-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.data-item {
    flex: 0 0 12%; /* Set fixed width of 12% for each data item */
    margin-right: 10px;
    position: relative;
}

.data-item:last-child {
    margin-right: 0;
}

.data-heading {
    font-weight: bold;
    color: #333;
    margin-bottom: 4px;
    font-size: 14px;
    white-space: nowrap;
    padding: 6px;
}

.data-value {
    color: #555;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    padding-left: 6px;
}

.data-value:hover {
    border-color: #0078d4;
}

.error-heading {
    color: #d9534f;
    font-weight: bold;
    font-size: 14px;
}

.error-item {
    flex: 1; /* Error item takes the remaining space */
    min-width: 40%; /* Allow the error item to be at least 40% of the row width */
    margin-right: 10px;
}

.error-value {
    color: #d9534f;
    font-size: 14px;
    padding-left: 6px;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Allows text wrapping */
    word-break: break-word; /* Ensures long words are broken and wrapped */
}
.ErrDrpDownFilter {
    label {
        font-size: 12px;
        color: #000000;
        font-weight: 400;
        font-family: $font1;
    }

    .ms-TextField-fieldGroup {
        border: 1px solid #000000;
        border-radius: 2px;
    }

    .ms-Dropdown-title {
        border: 1px solid #000000;
        border-radius: 2px;
        height: 38px !important;
        line-height: 36px !important;
    }

    .ms-Dropdown {
        width: 345px;
    }

    .ms-Dropdown-caretDownWrapper {
        top: 0px;
        line-height: 40px !important;
    }
    .ms-TextField-prefix {
        background: #f4f4f487;
    }
}

.disable_error_link {
    pointer-events: none; /* Make it clickable */
    font-size: 13px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 0;
    color: #f443437d;
    display: contents; /* Ensure it behaves like a normal button */
    
}
.enable_error_link {
    pointer-events: auto;  /* Ensure the button is clickable */
    opacity: 1 !important;            /* Override the parent's opacity */
    position: relative;
    z-index: 1;
    margin-right: 30px;
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 0;
    border: none;
    background: none;
    color: #ff4444;        /* Button's text color */
}

.enable_error_link:hover {
    color: #ff0000;  /* Darker red when hovered */
    cursor: pointer;
}
.opacityBreaker{
    position: absolute;
    display: flex;
    margin-top: -83px;
    margin-left: 28%;
}
.pding{
    margin-right: 5px;
    margin-top: -1px;
}
.h-33 {
    height: 33px; //height set to asset add btn
  }
  